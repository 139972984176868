import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../../common';
import CustomerMobileHeader from "../../Layout/CustomerMobileHeader";
import {Skeleton, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PlanItem from '../../Plan/PlanItem';
import { actions } from '../../../redux/UserRedux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PlanList = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    
    const [customer_activations, setCustomerActivations] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [plan_id, setPlanId] = useState(0);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);
    const [eSIMName, setESIMName] = useState("");
    const [activation_id, setActivationId] = useState(0);

    const planList = useSelector((state) => state.user.planList);
    const pageInfo = useSelector((state) => state.user.pageInfo);
    const isLoading =  useSelector((state) => state.user.isLoading);
    const colorData = useSelector((state) => state.user.colorData);
    const errorMsg = useSelector((state) => state.user.errorMsg);
    const showMsg = useSelector((state) => state.user.showMsg);

    const fetchPlanDetails = async (plan_id) => {
        sessionStorage.setItem('plan_id', plan_id);
        sessionStorage.setItem('activation_id', pageInfo.id);
        if(pageInfo.telsim !== undefined && pageInfo.telsim != null){
            sessionStorage.setItem('telsim', pageInfo.telsim);
        }
        sessionStorage.setItem('type', pageInfo.pageType);
        sessionStorage.setItem('colour', JSON.stringify(colorData));
        history.push('/plandetails');
    }
    
    if(showMsg == true){
        //showMessage(errorMsg, Constants.SnackBarType.Error)
    }

    //show messages
    const showMessage = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };
    
    return(
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet" style={{marginTop: 20}}>
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        {isLoading == false ? (
                                        <h3 className="kt-portlet__head-title">{sessionStorage.getItem('bundle') == Languages.TopUp ? Languages.TopupESIM : pageInfo.pageType}</h3>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                        )}
                                    </div>
                                </div>
                                {/* <CustomerMobileHeader/> */}
                                {isLoading == false ? (<>
                                    <div className='authHideView' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column'}}>
                                        <div className='row' style={{marginLeft: 50, marginRight: 50}}>
                                            {planList.map(item => (
                                                <PlanItem
                                                key={"" + item.plan_id}
                                                type={pageInfo.pageType}
                                                planData={item}
                                                colorInfo={colorData}
                                                onShowDetails={()=> {
                                                    fetchPlanDetails(item.plan_id)
                                                }}/>
                                            ))}
                                        </div> 
                                    </div>
                                    <div className='hideMobileView'>
                                        <div className='row' style={{marginLeft: 10, marginRight: 10}}>
                                            {planList.map((item) => (
                                                <PlanItem 
                                                key={"" + item.plan_id}
                                                type={pageInfo.pageType}
                                                colorInfo={colorData}
                                                planData={item}
                                                onShowDetails={()=> {
                                                    fetchPlanDetails(item.plan_id)
                                                }}/>
                                            ))}
                                        </div>
                                    </div>
                                </>) : (
                                    <div className='row' style={{marginLeft: 10, marginRight: 10}}>
                                        {Constants.ButtonList.map(item=>(
                                        <div className='col-lg-4'>
                                             <Skeleton className='planitemBox' animation="wave" sx={{borderRadius: 1}} height={250}/>
                                        </div>))}

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(PlanList);