import * as React from 'react';
import { useState, useRef, useEffect, PureComponent } from 'react';
import { Link, withRouter, useHistory} from "react-router-dom";
import {Skeleton, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { setUserSession, getApiHeaderGuest } from '../../utils/CommonFunctions';
import LogoImg from '../../assets/media/esim/esim_logo.png'
import LogoWhite from '../../assets/img/logowhite.png';
import Check from '../../assets/img/check.png'
import ForgotImg from '../../assets/img/forgot.png'
import { UserAPI } from '../../services';
import {Validate, Colors, Constants, Languages} from '../../common';

const headers 		= getApiHeaderGuest();
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ForgotPassword = () => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [isSend, setIsSend] = useState(false)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);

    const onEmailEditHandle = (event) => {
        setEmail(event.target.value);
    }

    const validateForm = () => {
        if(Validate.isEmpty(email)){
            return Languages.EmailReqError;
        }else if(!Validate.isEmail(email)){
          return Languages.EmailError;
        }
        return undefined;
    }

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }
    
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };

    const onContinueClickHandle = async () => {
        const error = validateForm();
        if(error) return showMsg(error, Constants.SnackBarType.Error);
        const data = {
            email: email
        }
        setIsLoading(true);
        const response = await UserAPI.postData(data, headers, Constants.ForgotPassword)
        console.log('Forgot password ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                showMsg(response.message, Constants.SnackBarType.Success)
                setIsSend(true);
                setIsLoading(false)
            } else if(response.status == 422){
                var arr = [];
                arr = Object.keys(response.message).map(key=>response.message[key][0])
                setIsLoading(false)
                showMsg(arr, Constants.SnackBarType.Error)
            } else {
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }
    const gotoLogin = () => {
        history.push('/Login');
        //window.location.href = "/Login";
    }

    return(
        <div className="kt-grid kt-grid--ver kt-grid--root login">
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2 backImage login" id="kt_login_v2">
                <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid loginCenterView">
                    <div>
                        <div className='authHideView' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
                            <img style={{height: 70, width: 150}} src={LogoWhite} alt="" />
                        </div>
                        <div className='hideMobileView' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
                            <img style={{height: 70, width: 150}} src={LogoImg} alt="" />
                        </div>
                        <h4 className="text-center headerColor" style={{marginTop: 30}}>{Languages.ForgotPassword}</h4>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40}}/>
                        <div className='boxView authHideView' style={{marginTop: 30}}>
                            <form className="loginForm">
                               { isSend == false ? (
                                <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {isLoading == false ? (<img style={{height: 70, width: 150}} src={ForgotImg} alt="" />) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, display: 'flex'}} variant="rectangular" height={70} width={150}/>
                                        )}
                                    </div>
                                    {isLoading == false ? (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <label className="form-check-label authInfo" style={{color: Colors.Black, fontSize: 13}}>{Languages.ForgotInfo}</label> 
                                    </div>) : (
                                        <div className="form-group" >
                                            <div>
                                                <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 4}} variant="text"/>
                                                <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                                            </div>
                                        </div>
                                    )}
                                   
                                    <div className="form-group" style={{marginTop: 10}}>
                                        {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                                            {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                                            <input name="email" type="text" className="form-control" placeholder={Languages.Email} value={email} onChange={onEmailEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: '#e5e5e5', height: 48, marginRight: 40, marginLeft: 40,}}/>
                                        </div>) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                                        )}
                                        {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
                                    </div>
                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                        {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={onContinueClickHandle}>{Languages.Continue}</button>
                                        ) : (
                                            <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                                        )}
                                    </div>
                                </div>
                                ) : (
                                    <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <div className='circleView centerView'>
                                                <img style={{height: 50, width: 50}} src={Check} alt="" />
                                            </div>
                                            {/* <img style={{height: 70, width: 150}} src={LogoImg} alt="" /> */}
                                        </div>
                                        <h4 className="text-center" style={{marginTop: 30}}>{Languages.ForgotSuccessInfo}</h4>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <label className="form-check-label authInfo" style={{color: Colors.Black, fontSize: 13, marginTop: 10}}>{Languages.EmailSendInfo1 + email + Languages.EmailSendInfo2}</label> 
                                        </div>
                                        
                                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                                            <button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={gotoLogin}>{Languages.GotIt}</button>
                                        </div>
                                    </div>
                               )}
                            </form>
                        </div>
                        <div className='hideMobileView'>
                            <form className="loginForm">
                               { isSend == false ? (
                                <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {isLoading == false ? (<img style={{height: 70, width: 150}} src={ForgotImg} alt="" />
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, display: 'flex'}} variant="rectangular" height={70} width={150}/>
                                        )}
                                    </div>
                                    {isLoading == false ? (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <label className="form-check-label authInfo" style={{color: Colors.Black, fontSize: 13}}>{Languages.ForgotInfo}</label>  
                                    </div>
                                    ) : (
                                        <div className="form-group" >
                                            <div>
                                                <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 4}} variant="text"/>
                                                <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group" style={{marginTop: 10}}>
                                        {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                                            {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                                            <input name="email" type="text" className="form-control" placeholder={Languages.Email} value={email} onChange={onEmailEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: '#e5e5e5', height: 48, marginRight: 40, marginLeft: 40,}}/>
                                        </div>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                                        )}
                                        {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
                                    </div>
                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                                        {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={onContinueClickHandle}>{Languages.Continue}</button>
                                        ) : (
                                            <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                                        )}
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <label className="form-check-label authInfo" style={{color: Colors.White, fontSize: 13}}>{Languages.ForgotInfo}</label>  
                                    </div>
                                </div>
                                ) : (
                                    <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 20, paddingBottom: 30}}>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <div className='circleView centerView'>
                                                <img style={{height: 35, width: 35}} src={Check} alt="" />
                                            </div>
                                            {/* <img style={{height: 70, width: 150}} src={LogoImg} alt="" /> */}
                                        </div>
                                        <h4 className="text-center" style={{marginTop: 30}}>{Languages.ForgotSuccessInfo}</h4>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <label className="form-check-label authInfo" style={{color: Colors.Black, fontSize: 13, marginTop: 10}}>{Languages.EmailSendInfo1 + email + Languages.EmailSendInfo2}</label> 
                                        </div>
                                        
                                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                                            <button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={gotoLogin}>{Languages.GotIt}</button>
                                        </div>
                                    </div>
                               )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}
export default withRouter(ForgotPassword);