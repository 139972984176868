import * as React from 'react';
import { useState } from 'react';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Validate, Colors, Languages} from '../../common';
import { styled, useTheme } from '@mui/material/styles';
import { Switch, Paper, useMediaQuery, Grid, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
//import planCard from '../../assets/img/eSIM-Australia-Plan-Card.png';
const planCard = "http://tes.estest.link/wp-content/uploads/2023/05/eSIM-Canada-Plan-Card.png"








const PlanItem = ({onShowDetails, planData, type, colorInfo, topupRdTxt}) => {


const history = useHistory();

const topupAlert = async (textval) => {
   
        swal({
            title: textval,
            text: "", 
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
               history.push('/purchaseplan');
          } else {
            
          }
        });

}




console.log('plan_image ==>', planData.plan_image);
    return(
        <div className='col-lg-4'>
            <div id='box'
            className='planBox pBox' style={{background: 'linear-gradient(to bottom, ' + colorInfo.top + ' 0%, ' + colorInfo.bottom + ' 100%)'}}>
                {(planData.plan_image !== undefined && planData.plan_image != null) && (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1}}>
                    <img src={planData.plan_image} className="img-fluid imgView" alt=""/>
                </div>)}
                
                <h3>{planData.common_plan_name}</h3>
                <h5>{ type == Languages.Local ? planData.country_name : (type == Languages.Regional ? planData.region_short_name : (type == Languages.GlobalSIM ? Languages.Global : planData.country_name))}</h5>
                <hr />
                <b><i className="fa fa-signal"></i> {Languages.Data} <span>{planData.plan_data + ' ' + Languages.GB}</span></b><br />
                <hr></hr>
                {/* <b><i className="fa fa-calendar"></i> {Languages.Validity} <span>{planData.plan_validity + ' ' + Languages.Days}</span></b> */}
                <b><i className="fa fa-calendar"></i> {Languages.Validity} <span>{planData.plan_validity + ' ' + (planData.plan_validity > 1 ? Languages.Days : "Day")}</span></b>
                <hr></hr>
                <b><i className="fa fa-usd"></i> {'Price'} <span style={{fontSize: 16}}>${planData.plan_amount}</span></b>
                { topupRdTxt == null ?
                <a><button type="button" className="value-btn-1 valueBtn" onClick={onShowDetails}>{Languages.BuyNow}</button></a>
                :
                <a><button type="button" className="value-btn-1 valueBtn" onClick={()=> { topupAlert(topupRdTxt)  }} >{Languages.BuyNow}</button></a>

                }
            </div>
        </div>
    )
}
export default PlanItem;