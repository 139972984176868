import React from "react";
import { Constants } from "../../common";

function Footer() {
  return (
    <>
        <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop footer-border">
			<div className="kt-container  kt-container--fluid ">
				<div className="kt-footer__copyright" style={{textAlign: 'center', fontSize: 9}}>
					Copyright © 2023 | eSIM Australia | All Rights Reserved
				</div>
				<div className="kt-footer__menu">
					<a href={Constants.AboutUs} target="_blank" className="kt-footer__menu-link kt-link">About Us</a>
					<a target="_blank" href={Constants.Legals} className="kt-footer__menu-link kt-link">Legals</a>
					<a href={Constants.ContactUs} target="_blank" className="kt-footer__menu-link kt-link">Contact Us</a>
				</div>
			</div>
		</div> 
    </>
  );
}

export default Footer;
