import * as React from 'react';
import { useState, useEffect } from 'react';
import {getApiHeader} from '../../../utils/CommonFunctions'; 
import { Constants, Languages, Colors } from "../../../common";
import { UserAPI } from '../../../services';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {Skeleton} from '@mui/material';

const CheckoutForm = ({showMsg, close, getCardList}) => {
  
  const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const [isFormMounted, setIsFormMounted] = useState(false);

    useEffect(() => {
      setIsFormMounted(true);
  
      return () => {
        setIsFormMounted(false);
      };
    }, []);

    const addNewCard = async (cardId, cardToken) => {
      var postData = {
        stripeCardTokenId: cardToken,
        stripeCardId: cardId
      }
      let headers 	= await getApiHeader();
      setIsLoading(true)
      const response = await UserAPI.postData(postData, headers, Constants.AddCard)
      console.log('AddCard ==>', response);
      if(response !== undefined){
        if(response.status == 200){
          showMsg(response.message, Constants.SnackBarType.Success)
          setIsLoading(false)
          getCardList();
          close();
        } else {
          //this.setState({isLoading: false})
          setIsLoading(false)
          showMsg(response.message, Constants.SnackBarType.Error)
        }
      }else {
        setIsLoading(false)
        //this.setState({isLoading: false})
        showMsg(Languages.ServerError, Constants.SnackBarType.Error);
      }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          return;
        }
        //setIsLoading(true);
        try{
          const cardElement = elements.getElement(CardElement);
          console.log('cardElement ==>', cardElement);

          const {error, token} = await stripe.createToken(cardElement);
  
          if (error) {
            console.error(error);
            //setIsLoading(false)
            showMsg(error.message, Constants.SnackBarType.Error);
          } else {
            console.log(token.id, token.card.id, token);
            addNewCard(token.card.id, token.id)
            // Send the token.id to your server to save the card to a customer's Stripe account
          }
        }catch(e){
          //setIsLoading(false);
          console.log('error ==>', e);
        }
        

        
        
        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //   type: 'card',
        //   card: cardElement
        // });
        // if (error) {
        //   console.error(error);
        // } else {
        //   // You can now submit the paymentMethod.id to your server for further processing
        //   console.log(paymentMethod);
        //  var paymentMethodId = paymentMethod.id;
        //  //var customerId = 'cus_O2kDKDC4wlG6CA';

        //   console.log(paymentMethodId, paymentMethod);
        // }
    };

  return (
    <div>
      {isLoading == false ? (<div style={{paddingTop: 10}}>
        <CardElement options={{ hidePostalCode: true }}/>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 20}}>
          <button style={{marginTop: 10, height: 35, width: '25%', backgroundColor: Colors.Primary, color: Colors.White, borderWidth: 0, borderRadius: 5}} disabled={!stripe} onClick={handleSubmit}>Submit</button>
        </div>
      </div>) : (
        <div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Skeleton animation="wave" width={'65%'} height={40}/>
            <Skeleton animation="wave" width={'20%'} height={40}/>
            <Skeleton animation="wave" width={'10%'} height={40}/>
          </div>
          <Skeleton animation="wave" width={'20%'} height={60}/>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;