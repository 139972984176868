import * as React from 'react';
//import React, { Component } from "react";
import { install } from "resize-observer";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import store from '../src/store/configureStore'
import Router from './Router';

install();

const App = () => {

    const persistor = persistStore(store);
    
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router />
            </PersistGate>
        </Provider>
    )
}
export default App;