import * as React from 'react';
import { useState, useRef, useEffect, PureComponent } from 'react';
import { Link} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Skeleton, Stack} from '@mui/material';

const isValidName = /[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/;

const Register = ({isLoading, onRegister, showMsg, sendOTP}) => {
    const [email, setEmail] = useState("");
    const [otp, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)

    const onEmailEditHandle = (event) => {
        setEmail(event.target.value);
    }
    const onOTPEditHandle = (event) => {
        if(event.target.value.length <= 6){
            setOTP(event.target.value);
        }
    }

    const onPasswordEditHandle = (event) => {
        setPassword(event.target.value);
    }

    const onCPasswordEditHandle = (event) => {
        setConPassword(event.target.value);
    }

    const onFirstNameEditHandle = (event) => {
        if(!event.target.value.match(isValidName)){
            setFirstName(event.target.value);
        }
        //setFirstName(event.target.value);
    }

    const onLastNameEditHandle = (event) => {
        if(!event.target.value.match(isValidName)){
            setLastName(event.target.value);
        }
        //setLastName(event.target.value);
    }

    const validateForm = () => {
        if(sendOTP == false){
            if(Validate.isEmpty(firstName, lastName, email, password, conPassword)){
                return Languages.FormError;
            } else if(!Validate.isEmail(email)){
                return Languages.EmailError;
            } else if(password != conPassword) {
                return Languages.passwordError;
            }
        }else {
            if(Validate.isEmpty(otp)){
                return Languages.FormError;
            }
        }
        return undefined;
    }

    const onRegisterClickHandle = () => {
        const error = validateForm();
        if(error) return showMsg(error);
        onRegister(firstName, lastName, email, password, conPassword, otp);

    }
    
    return(
        <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
            <div className="form-group" style={{marginTop: 40}}>
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 40}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="firstname" type='text' className="form-control" placeholder={Languages.FirstName} value={firstName} onChange={onFirstNameEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: Colors.InputText, height: 48, marginRight: 40, marginLeft: 40,}}/>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
               {isLoading == false ? ( <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="lastname" type="text" className="form-control" placeholder={Languages.LastName} value={lastName} onChange={onLastNameEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: Colors.InputText, height: 48, marginRight: 40, marginLeft: 40,}}/>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="email" type="text" className="form-control" placeholder={Languages.Email} value={email} onChange={onEmailEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: Colors.InputText, height: 48, marginRight: 40, marginLeft: 40,}}/>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="password" type={showPassword == false ? 'password' : 'text'} className="form-control" placeholder={Languages.Password} value={password} onChange={onPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: Colors.InputText, height: 48, marginLeft: 40,}}/>
                    <div onClick={()=>{setShowPassword(!showPassword)}} className='pointer' style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48}}>{showPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="confirm_password" type={showCPassword == false ? 'password' : 'text'} className="form-control" placeholder={Languages.ConfirmPassword} value={conPassword} onChange={onCPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: Colors.InputText, height: 48, marginLeft: 40,}}/>
                    <div onClick={()=>{setShowCPassword(!showCPassword)}} className='pointer' style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48}}>{showCPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            {/* {sendOTP == true && (<div className="form-group">
            {isLoading == false ? (<label className="form-check-label authInfo" style={{marginTop: -5}}>{Languages.OTPInfo}
                    <b className="kt-link kt-link--brand" style={{color: Colors.authInfo}}>
                        {email}
                    </b>
                    {Languages.OTPInfo2}
                </label>) : (
                    <div>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 4}} variant="text"/>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                    </div>
                )} 
            </div>)} */}

            {sendOTP == true && (<div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -8}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="otp" type="number" className="form-control" placeholder={Languages.OTP} value={otp} onChange={onOTPEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: Colors.InputText, height: 48, marginRight: 40, marginLeft: 40}}/>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>)}
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 40}}>
                {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={onRegisterClickHandle}>{sendOTP == true ? Languages.Register : Languages.SendOTP}</button>
                ) : (
                    <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                )}
            </div>
            <div className="form-group" >
                {isLoading == false ? (<label className="form-check-label authInfo">{Languages.RegisterInfo1}
                    <a href={Constants.TermsConditions} target="_blank" className="kt-link kt-link--brand" style={{color: Colors.authInfo}}>
                        {Languages.TermsCondition}
                    </a>
                    {" and "}
                    <a href={Constants.PrivacyPolicy} target="_blank" className="kt-link kt-link--brand" style={{color: Colors.authInfo}}>
                        {Languages.PrivacyPolicy}
                    </a>
                    {Languages.RegisterInfo2}
                </label>) : (
                    <div>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 4}} variant="text"/>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                        <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1, marginTop: 0.5}} variant="text"/>
                    </div>
                )} 
            </div>
            {isLoading == true && (<div className="form-group" >
                <label className="form-check-label authInfo"  style={{color: Colors.White, marginTop: -60}}>{Languages.ForgotSuccessInfo}
                </label> 
            </div>)}
        </div>
    );
}
export default Register;