import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Link, withRouter} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Skeleton, Stack} from '@mui/material';

const ChangePassword = ({isLoading, onSubmit, showMsg}) => {
    
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [conPassword, setCPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const [showNPassword, setShowNPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)

    const onPasswordEditHandle = (event) => {
        setPassword(event.target.value);
    }

    const onNewPasswordEditHandle = (event) => {
        setNewPassword(event.target.value);
    }

    const onConPasswordEditHandle = (event) => {
        setCPassword(event.target.value);
    }

    const validateForm = () => {
        if(Validate.isEmpty(password, newPassword, conPassword)){
            return Languages.FormError;
        }else if(password == newPassword){
          return Languages.NewPasswordError;
        } else if(newPassword != conPassword){
            return Languages.passwordError
        }
        return undefined;
    }

    const onSubmitPressHandle = () => {
        const error = validateForm();
        if(error) return showMsg(error);
        onSubmit(password, newPassword, conPassword);
    }
    
    return(
        <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -10, paddingTop: 10, paddingBottom: 30}}>
           
            <div className="form-group" style={{marginTop: 20}}>
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="password" type={showPassword == false ? "password" : 'text'} className="form-control" placeholder={Languages.CurrentPassword} value={password} onChange={onPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#e5e5e5', height: 48, marginLeft: 5}}/>
                    <div onClick={()=>{setShowPassword(!showPassword)}} style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40}}>{showPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="password" type={showNPassword == false ? "password" : 'text'} className="form-control" placeholder={Languages.NewPassword} value={newPassword} onChange={onNewPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#e5e5e5', height: 48, marginLeft: 5}}/>
                    <div onClick={()=>{setShowNPassword(!showNPassword)}} style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40}}>{showNPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="password" type={showCPassword == false ? "password" : 'text'} className="form-control" placeholder={Languages.ConfirmPassword} value={conPassword} onChange={onConPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#e5e5e5', height: 48, marginLeft: 5}}/>
                    <div onClick={()=>{setShowCPassword(!showCPassword)}} style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 40}}>{showCPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className='rowView'>
                
            </div>
            
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={onSubmitPressHandle}>{Languages.Submit}</button>
                ) : (
                    <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                )}
            </div>
        </div>
    );
}
export default ChangePassword;