import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import CustomerMobileHeader from "../Layout/CustomerMobileHeader";
import {getApiHeader} from '../../utils/CommonFunctions';
import Box from '@mui/material/Box';
import GraphView from '../Layout/GraphView';
import { styled, useTheme } from '@mui/material/styles';
import { UserAPI } from '../../services';
import {Skeleton, Snackbar, useMediaQuery} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { actions } from '../../redux/UserRedux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AccountSummary = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    //const [isLoading, setIsLoading] = useState(false);
    const [customer_activations, setCustomerActivations] = useState([]);
    const [balanceGCnt, setBalanceGCnt] = useState(0);
    // const [summaryDetails, setSummaryDetails] = useState(null);
    const [eSIMDetails, seteSIMDetails] = useState(null);
    const [eSIMMsg, seteSIMMsg] = useState("");
    const [eSIMQRCode, seteSIMQRCode] = useState("");
    const [remData, setRemData] = useState(0);
    const [remDays, setRemDays] = useState(0);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);
    //const [eSIMName, setESIMName] = useState("");
    //const [activation_id, setActivationId] = useState(0);
    const [expiryDate, setExpiryDate] = useState("");
    const [eSIMStatus, seteSIMStatus] = useState(Languages.Installed);
    const [eSIMPlan, seteSIMPlan] = useState("");
    const [eSIMNo, seteSIMNo] = useState("");
    const [isAvailable, setIsAvailable] = useState(false);

    const token = useSelector((state) => state.user.token);
    const ASummary = useSelector((state) => state.user.ASummary);
    const isLoading =  useSelector((state) => state.user.isLoading);
    const activationId = useSelector((state) => state.user.activationId);
    const eSIMName = useSelector((state) => state.user.eSIMName);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        getActivations();
    }, []);

    const getAccountSummary = useCallback((id) => {
        actions.getAccountSummary(dispatch, id);
    }, [dispatch]);

    const notAvailable = useCallback(() => {
        actions.notAvailable(dispatch);
    }, [dispatch]);

    const setActivationId = useCallback((activationId, eSIMName) => {
        dispatch(actions.setActivationId(activationId, eSIMName));
    }, [dispatch]);

    const openLoader = useCallback(() => {
        actions.openLoader(dispatch);
    }, [dispatch]);

    const closeLoader = useCallback(() => {
        actions.closeLoader(dispatch);
    }, [dispatch]);

    const getActivations = async () => {
        openLoader();
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData({}, headers, Constants.UserActivations)
        console.log('UserActivations ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                if(response.data.activations.length > 0){
                    //setIsLoading(false);
                    let data 		= response.data.activations; 
                    let esim_Name = '';
                    let activation_Id = 0
                    if(data.length > 0){
                        esim_Name = data[0].country_name + ' - ' + data[0].iccid;
                        activation_Id = data[0].activation_id
                    }
                    //setESIMName(esimName)
                    setCustomerActivations(data);
                    if(activationId == null){
                        await setActivationId(activation_Id, esim_Name)
                        await getAccountSummary(activation_Id); 
                    }else{
                        await setActivationId(activationId, eSIMName)
                        await getAccountSummary(activationId);  
                    }
                }else{
                    await notAvailable();
                }
                
            } else {
                closeLoader();
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            closeLoader();
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    };

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };

    const handleChange =  async(e) => { 
		var activation_id = e.target.selectedOptions[0].getAttribute('activation_id');
        let country_name = e.target.selectedOptions[0].getAttribute('country_name');
        let iccid = e.target.selectedOptions[0].getAttribute('iccid');
        console.log(activation_id, country_name, iccid);
        await setActivationId(activation_id, country_name + ' - ' + iccid)
		await getAccountSummary(activation_id);  
	};
    
    console.log(ASummary);
    return(
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet" style={{marginTop: 20}}>
                                <div className="kt-portlet__head">
                                    <div className='authHideView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div className="kt-portlet__head-label">
                                            {isLoading == false ? (
                                            <h3 className="kt-portlet__head-title">{Languages.AccountSummary}</h3>
                                            ) : (
                                                <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                            )}

                                        </div>
                                        {/* <h3 className="kt-portlet__head-title">{Languages.AccountSummary}</h3> */}
                                        {isLoading == false ? (<select className="form-select custom-select kt_selectpicker" style={{width: 250}} value={eSIMName} onChange={handleChange} name="activation">
                                            {/* <option value="">{Languages.SelectSIM}</option> */}
                                            { customer_activations.map((person, i )=> <option className="m--bg-success kt-font-inverse-success" country_name={person.country_name} iccid={person.iccid} activation_id={person.activation_id} value={person.country_name + ' - ' + person.iccid} key={i.toString()}>{person.country_name + ' - ' + person.iccid}</option>)} 

                                        </select>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, display: 'flex', width: 250, height: 35}}/>
                                        )}
                                    </div>
                                    <div className="kt-portlet__head-label hideMobileView">
                                        {isLoading == false ? (
                                        <h3 className="kt-portlet__head-title">{Languages.AccountSummary}</h3>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                        )}
                                    </div>
                                    
                                </div>
                                {isLoading == false ? (<select className="form-select custom-select kt_selectpicker hideMobileView" value={eSIMName} onChange={handleChange} name="activation">
                                    {/* <option value="">{Languages.SelectSIM}</option> */}
                                    { customer_activations.map((person, i )=> <option className="m--bg-success kt-font-inverse-success" country_name={person.country_name} iccid={person.iccid} activation_id={person.activation_id} value={person.country_name + ' - ' + person.iccid} key={i.toString()}>{person.country_name + ' - ' + person.iccid}</option>)} 

                                </select>
                                ) : (
                                    <Skeleton className='hideMobileView' animation="wave" sx={{borderRadius: 1, display: 'flex', flex: 1, height: 40}} height={40}/>
                                )}
                                {/* <CustomerMobileHeader/> */}
                                {ASummary.screen == 1 && (<>
                                    <div className='authHideView' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column'}}>
                                        {isLoading == false ? (<Box 
                                        sx={{
                                            display: 'flex',
                                            height: 300,
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            width: '85%',
                                            alignSelf: 'center',
                                            marginTop: 2
                                        }}>
                                            <div style={{display: 'flex', height: 300, flex: 1, alignItems: 'center', flexDirection: 'column', width: '50%', justifyContent: 'space-around'}}>
                                                <Box sx={{
                                                    borderRadius: 4,
                                                    height: 130,
                                                    display: 'flex',
                                                    width: '100%',
                                                    marginRight: 1,
                                                    marginLeft: 1,
                                                    backgroundColor: Colors.SummaryBack1,
                                                }}>
                                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                            <h1 style={{fontWeight: 'bold'}}>{ASummary.remData}</h1>
                                                            <h6>{Languages.AvailableData}</h6>
                                                        </div>

                                                    </div>

                                                </Box>
                                                <Box sx={{
                                                    borderRadius: 4,
                                                    height: 130,
                                                    display: 'flex',
                                                    width: '100%',
                                                    marginRight: 1,
                                                    marginLeft: 1,
                                                    backgroundColor: Colors.SummaryBack2,
                                                }}>
                                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                            <h1 style={{fontWeight: 'bold'}}>{ASummary.remDays}</h1>
                                                            <h6>{Languages.Remaining}</h6>
                                                        </div>

                                                    </div>

                                                </Box>
                                            </div>
                                            <Box sx={{
                                                borderRadius: 4,
                                                height: 300,
                                                display: 'flex',
                                                flex: 1,
                                                marginRight: 1,
                                                marginLeft: 1,
                                                marginTop: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: Colors.SummaryBack3,
                                            }}>
                                                <div style ={{height: 150, width: 300, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                
                                                <GraphView 
                                                balanceCnt={ASummary.balanceGCnt}/>
                                                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 30}}>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                        <div style={{width: 10, height: 10, borderRadius: 5, background: Colors.GraphBalance}}>
                                                        </div>
                                                        <label style={{color: Colors.Black, fontSize: 12, marginLeft: 10, marginTop: 5}}>{Languages.BalanceData}</label>

                                                    </div>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                        <div style={{width: 10, height: 10, borderRadius: 5, background: Colors.Gray}}>
                                                        </div>
                                                        <label style={{color: Colors.Black, fontSize: 12, marginLeft: 10, marginTop: 5}}>{Languages.DataUsed}</label>

                                                    </div>

                                                </div>
                                                </div>
                                            </Box>
                                        </Box>) : (
                                            <div style={{
                                                display: 'flex',
                                                height: 300,
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                width: '90%',
                                                alignSelf: 'center',
                                                marginTop: 20
                                            }}>
                                                <div style={{display: 'flex', height: 300, alignItems: 'center', flexDirection: 'column', width: '50%'}}>
                                                    <Skeleton animation="wave" sx={{borderRadius: 4, display: 'flex', height: 150,  width: '100%', marginLeft: 5}}/>
                                                    <Skeleton animation="wave" sx={{borderRadius: 4, display: 'flex', height: 150,  width: '100%', marginLeft: 5}}/>
                                                </div>
                                                <Skeleton animation="wave" sx={{borderRadius: 4, display: 'flex', height: 410, flex: 1, marginRight: 5, marginLeft: 5}}/>
                                            </div>
                                        )}
                                    </div>
                                    <div className='hideMobileView'>
                                        {isLoading == false ? (<Box 
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            marginTop: 2
                                        }}>
                                            <Box sx={{
                                                borderRadius: 4,
                                                height: 100,
                                                display: 'flex',
                                                flex: 1,
                                                marginRight: 1,
                                                marginLeft: 1,
                                                backgroundColor: Colors.SummaryBack1,
                                            }}>
                                                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                        <h1 style={{fontWeight: 'bold'}}>{ASummary.remData}</h1>
                                                        <h6>{Languages.AvailableData}</h6>
                                                    </div>

                                                </div>

                                            </Box>
                                            <Box sx={{
                                                borderRadius: 4,
                                                height: 100,
                                                display: 'flex',
                                                flex: 1,
                                                marginRight: 1,
                                                marginLeft: 1,
                                                backgroundColor: Colors.SummaryBack2,
                                            }}>
                                                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                        <h1 style={{fontWeight: 'bold'}}>{ASummary.remDays}</h1>
                                                        <h6>{Languages.Remaining}</h6>
                                                    </div>

                                                </div>

                                            </Box>
                                        </Box>) : (
                                            <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            marginTop: -20}}>
                                                <Skeleton animation="wave" sx={{
                                                borderRadius: 4,
                                                display: 'flex',
                                                flex: 1,
                                                height: 200,
                                                marginLeft: 1,}}/>
                                                <Skeleton animation="wave" sx={{
                                                borderRadius: 4,
                                                display: 'flex',
                                                flex: 1,
                                                height: 200,
                                                marginLeft: 1}}/>
                                            </div>
                                        )}
                                        {isLoading == false ? (<Box 
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            marginTop: 2
                                        }}>
                                            <Box sx={{
                                                borderRadius: 4,
                                                height: 150,
                                                display: 'flex',
                                                flex: 1,
                                                marginRight: 1,
                                                marginLeft: 1,
                                                backgroundColor: Colors.Secondary,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <div style ={{height: 130, width: '80%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <GraphView 
                                                balanceCnt={ASummary.balanceGCnt} />
                                                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 15}}>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                        <div style={{width: 10, height: 10, borderRadius: 5, background: Colors.GraphBalance}}>
                                                        </div>
                                                        <label style={{color: Colors.Black, fontSize: 12, marginLeft: 10, marginTop: 5}}>{Languages.BalanceData}</label>

                                                    </div>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                        <div style={{width: 10, height: 10, borderRadius: 5, background: Colors.Gray}}>
                                                        </div>
                                                        <label style={{color: Colors.Black, fontSize: 12, marginLeft: 10, marginTop: 5}}>{Languages.DataUsed}</label>

                                                    </div>

                                                </div>
                                                </div>
                                            </Box>
                                        </Box>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 4,
                                                display: 'flex',
                                                marginTop: -8,
                                                height: 200,
                                                marginLeft: 1,
                                                marginRight: 1}}/>
                                        )}
                                    </div>
                                </>)}
                                {(ASummary.eSIMStatus != Languages.Installed && ASummary.eSIMDetails != null && ASummary.screen == 3) && (<div className='hideMobileView'>
                                    {isLoading == false ? (<Box 
                                    className='tabBoxShadow'
                                    sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        marginTop: 4,
                                        marginRight: 1,
                                        marginLeft: 1,
                                        padding: 2
                                    }}>
                                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                <img style={{height: 100, width: 100}} src={ASummary.eSIMQRCode} alt="" />
                                                <h6 style={{marginTop: 20, textAlign: 'center'}}>{ASummary.eSIMMsg}</h6>
                                            </div>
                                        </div>

                                    </Box>) : (
                                        <Skeleton className='tabBoxShadow' animation="wave" sx={{
                                            borderRadius: 4,
                                            display: 'flex',
                                            marginTop: -1,
                                            height: 200,
                                            marginLeft: 1,
                                            marginRight: 1}}/>
                                    )}
                                </div>)}
                                
                               {ASummary.screen == 4 && ( <div className='col-lg-12' style={{padding: isMobile ? 20 : 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? 10 : 50}}>
                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        {isLoading == false ? (<i class="fa fa-qrcode" style={{fontSize: '6em'}} aria-hidden="true"></i>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 100,
                                                width: 100}}/>
                                        )}
                                        {isLoading == false ? (<h4 style={{marginTop: 10, textAlign: 'center', color: Colors.Black}}>{Languages.NoeSIM}</h4>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 30,
                                                width: 200,
                                                marginTop: -1}}/>
                                        )}
                                        {isLoading == false ? (<h6 style={{marginTop: 10, textAlign: 'center'}}>{Languages.NoeSIMInfo}</h6>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 30,
                                                width: 200,
                                                marginTop: -1}}/>
                                        )}
                                        {isLoading == false ? (<div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 10}}
                                         onClick={()=>{
                                            history.push('/purchaseplan')
                                        }}>
                                         {Languages.NoSIMBtnTxt}
                                        </div>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 40,
                                                width: 100,
                                                marginTop: 1}}/>
                                        )}
                                    </div>
                                </div>)}

                                {ASummary.screen == 2 && ( <div className='col-lg-12' style={{padding: isMobile ? 20 : 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? 10 : 50}}>
                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        {isLoading == false ? (<i class="fa fa-laptop" style={{fontSize: '3em'}} aria-hidden="true"></i>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 100,
                                                width: 100}}/>
                                        )}
                                        {isLoading == false ? (<h4 style={{marginTop: 10, textAlign: 'center', color: Colors.Black}}>{Languages.BundleQueued}</h4>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 30,
                                                width: 200,
                                                marginTop: -1}}/>
                                        )}
                                        {isLoading == false ? (<h6 style={{marginTop: 10, textAlign: 'center'}}>{ASummary.eSIMPlan + Languages.QueuedInfo}</h6>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 30,
                                                width: 200,
                                                marginTop: -1}}/>
                                        )}
                                    </div>
                                </div>)}

                                {ASummary.screen == 5 && ( <div className='col-lg-12' style={{padding: isMobile ? 20 : 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? 10 : 50}}>
                                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        {isLoading == false ? (<i class="fa fa-laptop" style={{fontSize: '3em'}} aria-hidden="true"></i>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 100,
                                                width: 100}}/>
                                        )}
                                        {isLoading == false ? (<h4 style={{marginTop: 10, textAlign: 'center', color: Colors.Black}}>{Languages.NoPlanInfo}</h4>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 30,
                                                width: 200,
                                                marginTop: -1}}/>
                                        )}
                                        {isLoading == false ? (<div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20}}
                                        onClick={()=>{
                                            history.push('/topup')
                                        }}>
                                         {Languages.TopUPESIMBtn}
                                        </div>) : (
                                            <Skeleton animation="wave" sx={{
                                                borderRadius: 2,
                                                height: 40,
                                                width: 100,
                                                marginTop: 1}}/>
                                        )}
                                    </div>
                                </div>)}



                                {(ASummary.eSIMStatus != Languages.Installed && ASummary.eSIMDetails != null && ASummary.screen == 3) && (<div className='authHideView' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                                    {isLoading == false ? (<Box 
                                    className='tabBoxShadow'
                                    sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '85%',
                                        justifyContent: 'space-around',
                                        marginTop: 4,
                                        
                                    }}>
                                        <Box sx={{
                                            borderRadius: 4,
                                            //height: '',
                                            display: 'flex',
                                            flex: 1,
                                            marginRight: 1,
                                            marginLeft: 1,
                                            padding: isMobile ? 2 : 5
                                        }}>
                                            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                    <img style={{height: 100, width: 100}} src={ASummary.eSIMQRCode} alt="" />
                                                    <h6 style={{marginTop: 30, textAlign: 'center'}}>{ASummary.eSIMMsg}</h6>
                                                </div>
                                            </div>
                                        </Box>
                                    </Box>) : (
                                        <Skeleton className='tabBoxShadow' animation="wave" sx={{
                                            borderRadius: 4,
                                            height: 250,
                                            width: '90%',
                                            marginTop: -8}}/>
                                    )}
                                </div>)}

                                {ASummary.screen == 1 && (<>
                                <div className='hideMobileView'>
                                    {isLoading == false ? (<Box 
                                    className='tabBoxShadow'
                                    sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        marginTop: 4,
                                        marginRight: 1,
                                        marginLeft: 1,
                                    }}>
                                        <Box sx={{
                                            borderRadius: 4,
                                            //height: '',
                                            display: 'flex',
                                            flex: 1,
                                            marginRight: 1,
                                            marginLeft: 1,
                                            
                                        }}>
                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', padding: 20}}>
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
														<div className="kt-widget__link" style={{marginTop: 10, borderColor: Colors.Primary, borderBottomWidth: 2}}>
															<i className="flaticon2-paperplane  kt-font-brand"></i> {Languages.CurrentPlan} : &nbsp;<a>{ASummary.eSIMPlan}</a>
														</div>
                                                        <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMNumber} : &nbsp;<a>{ASummary.eSIMNo}</a>
														</div>
                                                        {/* <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMName} : &nbsp;<a>{'eSIM Name'}</a>
														</div> */}
                                                        <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMStatus} : &nbsp;<a>{ASummary.eSIMStatus}</a>
														</div>
														<div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-time  kt-font-brand"></i> {Languages.Expiry} : &nbsp;<a> {ASummary.expiryDate}</a>
														</div>
														<div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-pie-chart-2  kt-font-brand"></i> {Languages.DataBalance} : &nbsp;<a>{ASummary.remData}</a>
														</div>
														<div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-calendar-3  kt-font-brand"></i> {Languages.DaysRemaining} : &nbsp;<a>{ASummary.remDays} </a>
														</div>
													</div>
												</div>
                                            </div>
                                        </Box>
                                    </Box>) : (
                                        <Skeleton className='tabBoxShadow' animation="wave" sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        marginTop: ASummary.eSIMStatus != Languages.Installed ? -11 : -9,
                                        height: 300,
                                        marginLeft: 1,
                                        marginRight: 1}}/>
                                    )}
                                </div>
                                <div className='authHideView' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10}}>
                                    {isLoading == false ? (<Box 
                                    className='tabBoxShadow'
                                    sx={{
                                        borderRadius: 4,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '85%',
                                        justifyContent: 'space-around',
                                        marginTop: 4
                                    }}>
                                        <Box sx={{
                                            borderRadius: 4,
                                            //height: '',
                                            display: 'flex',
                                            flex: 1,
                                            marginRight: 1,
                                            marginLeft: 1,
                                            //backgroundColor: Colors.SummaryBack1,
                                        }}>
                                            
                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', padding: 20}}>
                                                
                                               
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
                                                        <div className="kt-widget__link" style={{marginTop: 10}}>
															{/* <i className="flaticon2-paperplane  kt-font-brand"></i> Current Plan : &nbsp;<a> ${account_details.amount} / {account_details.total_data} GB</a> */}
															<i className="flaticon2-paperplane  kt-font-brand"></i> {Languages.CurrentPlan} : &nbsp;<a>{ASummary.eSIMPlan}</a>
														</div>
                                                        <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMNumber} : &nbsp;<a>{ASummary.eSIMNo}</a>
														</div>
                                                        {/* <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMName} : &nbsp;<a>{'eSIM Name'}</a>
														</div> */}
                                                        <div className="kt-widget__link " style={{marginTop: 10}}>
															<i className="flaticon2-phone  kt-font-brand "></i> {Languages.eSIMStatus} : &nbsp;<a>{ASummary.eSIMStatus}</a>
														</div>
													</div>
												</div>
												<div className="kt-widget__links">
													<div className="kt-widget__cont">
                                                        <div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-time  kt-font-brand"></i> {Languages.Expiry} : &nbsp;<a> {ASummary.expiryDate}</a>
														</div>
														<div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-pie-chart-2  kt-font-brand"></i> {Languages.DataBalance} : &nbsp;<a> {ASummary.remData}</a>
															
														</div>
														<div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon2-calendar-3  kt-font-brand"></i> {Languages.DaysRemaining} : &nbsp;<a>{ASummary.remDays} </a>
														</div>
														{/* <div className="kt-widget__link" style={{marginTop: 10}}>
															<i className="flaticon-star  kt-font-brand"></i>Wallet Balance	 : &nbsp;<a style={{fontSize: 14}}> ${account_details.credit_balance}</a>
														</div> */}
													</div>
												</div>
                                            </div>
                                        </Box>
                                    </Box>) : (
                                        <Skeleton className='tabBoxShadow' animation="wave" sx={{
                                        borderRadius: 4,
                                        height: 300,
                                        width: '90%',
                                        marginTop: ASummary.eSIMStatus != Languages.Installed ? - 11 : -4}}/>
                                    )}
                                </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(AccountSummary);