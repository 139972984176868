const Languages = {
    //Auth
    Login: 'Login',
    Register: 'Register',
    Email: 'Email',
    Password: 'Password',
    ForgotPassword: 'Forgot Password?',
    SignIn: 'Sign In',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Optional: '(Optional)',
    ConfirmPassword: 'Confirm Password',
    TermsCondition: 'Terms & Conditions',
    PrivacyPolicy: 'Privacy Policy',
    RegisterInfo1: 'By registering, you agree to our ',
    RegisterInfo2: '. Do note that we will not share your personal information with anyone outside of our company, and we hate spam too, so you\'ll never get spam emails from us.',
    ForgotPassword: 'Forgot Password',
    Continue: 'Continue',
    ForgotInfo: 'You can reset your password by entering your email down below. We\'ll then send you an email with a link to reset your password.',
    ForgotSuccessInfo: 'Your link has been successfully sent.',
    EmailSendInfo1: 'An email has been sent to ',
    EmailSendInfo2: ' with a link to reset your password.',
    GotIt: 'Ok, Got It',
    Platform: 'Web',
    OTP: 'Enter OTP',
    SendOTP: 'Send OTP',
    OTPInfo: 'Your verification code has been sent to ',
    OTPInfo2: ', please enter the 6-digits code down below and click \'Register\' button to complete your registration',

    //navigation drawer
    AccountSummary: 'Account Summary',
    MyProfile: 'My Profile',
    MyPayments: 'My Payments',
    AddNewESIM: 'Add New eSIM',
    TopupESIM: 'Top Up eSIM',
    Logout: 'Logout',

    //My payments
    PaymentMethod: 'Payment Method',
    AddNewMethod: '+ Add New',
    TransactionHistory: 'Transaction History',

    //Account Summary
    AvailableData: 'Remaining Data',
    PlanData: 'Plan Data',
    DataBank: 'Data Bank',
    Validity: 'Validity',
    Remaining: 'Remaining Days',
    CurrentPlan: 'Current Plan',
    eSIMNumber: 'eSIM Number',
    eSIMName: 'eSIM Name',
    eSIMStatus: 'eSIM Status',
    Expiry: 'Expiry',
    DataBalance: 'Data Balance',
    DaysRemaining: 'Days Remaining',
    DataUsed: 'Data Used',
    BalanceData: 'Balance Data',
    Release: 'Released',
    Installed: 'Installed',
    SelectSIM: 'Select eSIM',
    NoeSIM: 'No eSIMs associated with this account',
    NoeSIMInfo: 'When you purchase eSIMs they will be shown here.',
    NoSIMBtnTxt: 'Purchase eSIM',
    BundleQueued: 'Bundle Queued',
    QueuedInfo: ' is queued. Use data with this eSIM to start this bundle.',
    NoPlanInfo: 'No plan assigned to this eSIM',
    TopUPESIMBtn: 'Top-up eSIM',

    //Profile
    Details: 'Details',
    ChangePassword: 'Change Password',
    Name: 'Name',
    Email: 'Email',
    Address: 'Address',

    //Recharge
    Validity: 'VALIDITY',
    Data: 'DATA',
    BuyNow: 'BUY NOW',
    GB: 'GB',
    Days: 'Days',
    Coverage: 'COVERAGE',
    Price: 'PRICE',
    SupportedCountries: 'Supported Countries',
    AdditionalInformation: 'Additional Information',
    Network: 'NETWORK',
    PlanType: 'PLAN TYPE',
    ActivationPolicy: 'ACTIVATION POLICY',
    EKYC: 'eKYC (IDENTITY VERIFICATION)',
    TopUpOption: 'TOP-UP OPTION',
    PlanDetails: 'Plan Details',
    TopupAlert: 'Are you sure want to continue with this top-up?',
    TopupDetails: 'Top-up Details',
    
    //message icon
    TypeSuccess: 'success',
    TypeWarning: 'warning',
    TypeInfo: 'info',
    TypeError: 'error',

    //error
    FormError: 'Please complete the form.',
    LoginError: 'The Email and Password fields are required',
    ServerError: "Can't get the data from server.",
    EmailError: 'Invalid Email Address',
    passwordError: 'Password and confirm password must be same',
    EmailReqError: 'The email field is required.',
    NewPasswordError: 'Old password and New password must not be same',

    //purchase plan
    HeaderTxt1: 'Unleash the Power of Connectivity',
    HeaderTxt2: 'Shop eSIM Now!',
    SearchPlaceHolder: 'Search for Countries Here.',
    Local: 'Local eSIM',
    Regional: 'Regional eSIM',
    GlobalSIM: 'Global eSIM',
    Global: 'Global',
    RelatedPlans: 'Related Plans',
    TitleInfoDialog: 'What is the duration of your stay planned in Australia?',
    Button1: '1-7 Days',
    Button2: '8-15 Days',
    Button3: '16-30 Days',
    Button4: 'More than 30 Days',
    PurchaseAlert: 'Are you sure want to continue with this purchase?',
    PlanNetwork: 'Network',
    CheckSupNetWork: 'Check Supported Networks',
    SupportedNetWorks: 'Supported Networks',
    Country: 'Country',
    Region: 'Region',


    //Change Password
    CurrentPassword: 'Current password',
    NewPassword: 'New password',
    ConfirmPassword: 'Confirm password',
    Submit: 'Submit',

    //Top-up
    ChangeBundle: 'Change Bundle',
    HeaderTopupTxt: 'Top-Up eSIM Now!',
    Purchase: 'Purchase',
    TopUp: 'Top-Up'


}
export default Languages;