import * as React from 'react';
import { useState } from 'react';
import {Validate, Colors} from '../../../common';
import { styled, useTheme } from '@mui/material/styles';
import { Switch, Paper, useMediaQuery, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
        color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    },
    '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    },
    '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
        duration: 500,
    }),
    },
  }
));

const CardItem = ({data, onChange, isDefault, onDeleteCard}) => {

const [isMethod, setIsMethod] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    
    return (
        <Paper
          sx={{
            p: 2,
            width: isMobile ? '100%' : '40%',
            flexGrow: 1,
            marginBottom: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div" style={{fontSize: 12}}>
                  {data.brand}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{fontSize: 12}}>
                    {"**** **** **** " + data.last4}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {data.exp_month + '/' + data.exp_year}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div" style={{display: 'flex', justifyContent: 'flex-end', marginRight: -5}}>
                  <IOSSwitch sx={{ m: 1 }} checked={isDefault} onChange={(e)=>{
                    if(isDefault == false){
                      onChange(e, data)
                    }
                    }} />
                </Typography>
                <Typography variant="subtitle1" component="div" style={{color: Colors.White}}>
                  $19.00
                </Typography>
                <Typography variant="subtitle1" component="div" style={{display: 'flex', justifyContent: 'flex-end'}} onClick={()=>onDeleteCard(data)}>
                  <DeleteIcon sx={{ color: Colors.Primary }}/>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
}
export default CardItem;