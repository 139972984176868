import react from "react";
import { Route, Redirect } from "react-router-dom";
import {getToken} from "../../utils/CommonFunctions";

export const Routes = ({component: Component, ...rest})=>{
    return (
        <Route 
        {...rest}
        render={(props)=>{
            if (getToken() != null) {
                return <Component {...props} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
        }}
        />
        );
}