const Colors = {
    Primary: '#AA0000',//'#d6060d',
    Secondary: '#edf0f3',
    White: '#ffffff',
    Black: '#000',
    InputText: '#e5e5e5',
    authInfo: '#8a8a8a',
    Green: 'green',
    TableRow: '#f5f5f5',
    Gray: '#6c757d',
    TabHeader: '#9fa6ac',//'#8a929a'
    SummaryBack1: '#ffd1d1',//'#ffd1d1',//'#fff2cb'
    SummaryBack2: '#ffe5e5',//'#ffe5e5',//'#fff7df'
    SummaryBack3: '#fffcf2',//'#fff8f8',//'#fff8f8',//'#fff8f8'
    GraphColor: '#F50000',
    GraphBalance: '#4ac959'


}
export default Colors;