import * as React from 'react';
import Languages from './Languages';

const API_TOKEN = process.env.REACT_APP_API_TOKEN; 
const SERVER_URL 	= process.env.REACT_APP_SERVER_URL; 
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const STRIPE_SECRET = process.env.REACT_APP_STRIPE_SECRET

const Constants = {
    GraphData: {
        labels: ['Used Data', 'Balance Data'],
        datasets: [
            {
                label: 'Rainfall',
                backgroundColor: ['#e41e26', '#4ac959'],
                hoverBackgroundColor: ['#e41e26', '#4ac959'],
                data: [30, 70]
            }
        ]
    },
    SnackBarType: {
        Success: Languages.TypeSuccess,
        Info: Languages.TypeInfo,
        Warning: Languages.TypeWarning,
        Error: Languages.TypeError

    }, 
    planCard: [
        {
            name: 'Asia'
        },
        {
            name: 'Africa'
        },
        {
            name: 'Europe'
        },
        {
            name: 'Middle East'
        },
        {
            name: 'Latin America'
        },
        {
            name: 'North America'
        }
    ],
    ColorTheme: {
        top: "#00149c",
        bottom: "#0d0027",
        button: "#b10101"
    },
    SampleUser: {
        "customer_id": 36,
        "sal": "MR",
        "firstName": "John",
        "middleName": null,
        "surname": "Smith",
        "username": "amrithasunny11@gmail.com",
        "gender": "Female",
        "dob": "1994-11-27",
        "email": "amrithasunny112@gmail.com",
        "email_verified_at": null,
        "activation_completed": 1,
        "telstraCustNo": 200005150,
        "sms_notification": 1,
        "pgw_customer_id": "156149230",
        "has_my_account": 1,
        "my_account_status": 1,
        "dvs_status": 1,
        "dvs_attempt_count": 1,
        "dvs_failed_mail_send_status": 1,
        "stay_days": 0,
        "custType": "RE",
        "abn": null,
        "acn": null,
        "company": null,
        "tradingName": null,
        "created_at": "2021-10-27T18:51:28.000000Z",
        "updated_at": "2023-05-12T05:43:19.000000Z"
    },
    SampleToken: "3826|bPHbGjuCAAutU6a4Cm0IT4D8WHviEvY8EkpjPY5L",

    GraphCount: [
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
    ],
    GraphCntTot: 5,
    TotalPercentage: 100,
    lowestPercentage: 20,
    ButtonList: [
        {
            id: 1,
            name: Languages.Button1,
            startRange: 1, 
            endRange: 30
        },
        {
            id: 2,
            name: Languages.Button2,
            startRange: 1, 
            endRange: 30
        },
        {
            id: 3,
            name: Languages.Button3,
            startRange: 1, 
            endRange: 30
        },
        {
            id: 4,
            name: Languages.Button4,
            startRange: 30, 
            endRange: 30
        },
    ],

    //Stripe
    StripePublishKey: STRIPE_KEY,//'pk_test_51Mt2e4E25dJEkkS2cTTlYeQamn7p8rQlI944MCrb3tWai6YeKkq4FTcHDNQec9IlDmLujz1whJjt2eAuYjETCUlv00XWFVD4D8',//'pk_test_51NGbn5SGDuItm3MjFvSpfwef50CRDNbJUWR2PGdxXVs3TcabzvktTBn68r7kQh1nA4MjaWtcF3tGuNL8u4uNAAy300T1ihOpVu',
    StripeClientSecret: STRIPE_SECRET,//'sk_test_51NGbn5SGDuItm3MjX1cOANi1p8z9oNcihNQpibPKug4dfvROzJnVGlaF2hA03NI6guTJQuwOYjIdL4Zh04hETNrg00tTrH691a',

    //Site Urls
    AboutUs: 'https://www.esim.com.au/about-us/',
    ContactUs: 'https://www.esim.com.au/contact-us/',
    Legals: 'https://www.esim.com.au/legals/',

    TermsConditions: 'https://www.esim.com.au/terms-conditions/',
    PrivacyPolicy: 'https://www.esim.com.au/privacy-policy/',
    // APIs
    BaseUrl: SERVER_URL,
    // BaseUrl: 'http://web.estest.link/api/',
    // BaseUrl: 'http://web.checkus.info/api/',
    // BaseUrl: 'https://web.telsim.com.au/api/',
    ApiToken: API_TOKEN,
    Login: 'login',
    UserActivations: 'user-activations',
    AccountSummary: 'account-summary',
    ForgotPassword: 'forgot-password',
    AddCard: 'payments/add-card',
    CardList: 'payments/get-all-cards',
    DefaultCard: 'payments/default-card',
    DeleteCard: 'payments/delete-card',
    PaymentHistory: 'payments/history',
    ClientSecretKey: 'generate-client-secret-key',
    VerifyeSIM: 'verify-email',
    TopUp: 'top-up',
    Profile: 'profile',
    PlanList: 'plans-list',
    PlanDetails: 'plans-details',
    Register: 'register',
    VerifyRegisterOTP: 'customer_email_otp_verify',
    ChangePassword: 'password_change',
    Logout: 'logout',
    //AddNeweSIM
    CountryList: 'country-list',
    CountryPlanList: 'country-details',
    CountryPlanDetails: 'country-plan-details',
    RegionList: 'region-list',
    RegionPlanList: 'region-details',
    RegionPlanDetails: 'region-plan-details',
    GlobalPlanList: 'global-plan-listing',
    GlobalPlanDetails: 'global-plan-details',
    AddNewSIMClientSecret: 'add-new-esim/payment',
    AddNewSIMActivation: 'add-new-esim/activation',
    GetTelsimPlans: 'telsim-plan-listing',
    AddNewSIMTelsimPayment: 'add-new-esim/telsimPlanPayment',
    AddNewSIMTelsimActivation: 'add-new-esim/telsimPaymentComplete',
    AddNewSIMTelsimPlanDetails: 'telsim-plan-details',
    //Activation urls
    AttemptDetails: 'activation/attempt-details',
}
export default Constants;