import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import swal from '@sweetalert/with-react';
import LogoRed from '../../assets/media/esim/esim_logo.png';
import {getApiHeader, removeUserSession, getUser} from '../../utils/CommonFunctions'; 
import {Validate, Colors, Constants, Languages} from '../../common'
import { UserAPI } from '../../services';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL; 

class Navigation extends Component { 

	constructor(props) {
		super(props);
		this.state = {
			selected_number: null,
			sal: "",
			firstName: '',
			middleName: '',
			surname: '',
			email: ''
		}
	}

	componentDidMount = () =>{
		this.setData()
	}

	setData = async() => {
		var user = await getUser();
		this.setState({sal: user.sal, 
			firstName: user.firstName,
			surname: user.surname, 
			email: user.email
		})
	}

	handleLogout = async () => {  
		let headers 	= await getApiHeader();
		const response = await UserAPI.getData(headers, Constants.Logout);
		console.log('logout ==>', response);
		if(response !== undefined){
			if(response.status == 200){
				let p = new Promise((resolve, reject)=>{
					removeUserSession(); 
					resolve();
				})
				p.then(
					()=>{
						window.location.href = "/login";
					},
					()=>{
						alert("Error in logout");
					}
				)
			}
		}
	}

	menuClick=(path)=>{
		document.getElementById("kt_aside_close_btn").click();
		if(path == 'logout'){
			this.handleLogout();
		}
	}

	render()
	{
		const {sal, firstName, middleName, surname, email} = this.state;
		return (
			<>
				<button className="kt-aside-close " id="kt_aside_close_btn" style={{marginRight: 5}}><i className="fa fa-chevron-left" style={{color: Colors.Black}}></i></button>
				<div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside" style={{backgroundColor: '#f5f5f5'}}> 
					
					<div className="navDrawerHeaderView headerPadding" >
						<div className="columnView">
							<div className="navDrawerHeaderImgView">
								<img src={LogoRed} style={{height: 50}} alt="image"/>
							</div>
							<h5 className="text-center" style={{marginTop: 10}}>{sal} {firstName} {surname}</h5>
							<h6 className="text-center">{email}</h6>
						</div>
					</div>
					<div className="divider"></div>
					<div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper" style={{backgroundColor: '#f5f5f5'}}>
						<div id="kt_aside_menu" className="kt-aside-menu" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500" style={{backgroundColor: '#f5f5f5'}}>
							<ul className="kt-menu__nav" >
								<>
									<li className={`kt-menu__item  ${ ((this.props.location.pathname === "/home") || (this.props.location.pathname === "/")) ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('home')}} to="/home" className="kt-menu__link  active"><i className="kt-menu__link-icon flaticon2-graphic"></i><span className="kt-menu__link-text">{Languages.AccountSummary}</span></Link>
									</li>
									<li className={`kt-menu__item  ${ this.props.location.pathname === "/MyProfile" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('MyProfile')}} to="/MyProfile" className="kt-menu__link "><i className="kt-menu__link-icon flaticon-avatar"></i><span className="kt-menu__link-text">{Languages.MyProfile}</span></Link>
									</li> 
									<li className={`kt-menu__item  ${ this.props.location.pathname === "/MyPayments" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('MyPayments')}} to="/MyPayments" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-credit-card"></i><span className="kt-menu__link-text">{Languages.MyPayments}</span></Link>
									</li>
									<li className={`kt-menu__item  ${ this.props.location.pathname === "/purchaseplan" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('purchaseplan')}} to="/purchaseplan" className="kt-menu__link "><i className="kt-menu__link-icon fas fa-sim-card"></i><span className="kt-menu__link-text">{Languages.AddNewESIM}</span></Link>
									</li>
									<li className={`kt-menu__item  ${ this.props.location.pathname === "/topup" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('topup')}} to="/topup" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-bolt"></i><span className="kt-menu__link-text">{Languages.TopupESIM}</span></Link>
									</li>
									<li className={`kt-menu__item  ${ this.props.location.pathname === "/RechargeAFriend" ? "kt-menu__item--active" : ""  }`} aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
										<Link onClick={()=>{this.menuClick('logout')}} to="" className="kt-menu__link "><i className="kt-menu__link-icon fa fa-sign-out"></i><span className="kt-menu__link-text">{Languages.Logout}</span></Link>
									</li>
								</>
							</ul>
						</div>
					</div> 
				</div> 
			</>
		);
	}
}

export default withRouter(Navigation);


