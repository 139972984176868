import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Validate, Colors, Languages, Constants} from '../../common';
import { styled, useTheme } from '@mui/material/styles';
import {useHistory} from "react-router-dom";
import {Skeleton, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import { UserAPI } from '../../services';
import {getApiHeader} from '../../utils/CommonFunctions'; 
import {useStripe} from '@stripe/react-stripe-js'; 
import CountryItem from '../Pages/eSIM/CountryItem'
import PlanItem from './PlanItem';
import { actions } from '../../redux/UserRedux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {/* {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null} */}
      </DialogTitle>
    );
}

const PlanDetails = ({showMsg, setIsLoading, onContinue}) => {

    const stripe = useStripe();
    const history = useHistory();
    const dispatch = useDispatch();

    const [planDetails, setPlanDetails] = useState([]);
    const [plan_coverage, setPlanCoverage] = useState('');
    const [plan_data, setPlanData] = useState(0);
    const [plan_validity, setPlanValidity] = useState(0);
    const [plan_amount, setPlanAmount] = useState(0);
    const [plan_image, setPlanImage] = useState('');
    const [flag_image, setFlagImage] = useState('');
    const [relatedPlanList, setRelatedPlanList] = useState([])
    const [supportedCountry, setSupportedCountry] = useState([]);
    const [supportedCNetwork, setSupportedCountryNet] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [PlanType, setPlanType] = useState("")

    const open = useSelector((state) => state.user.isShowAlert);
    const activation_id = useSelector((state) => state.user.activationId);
    const alertMsg = useSelector((state) => state.user.alertMsg);
    const alertPage = useSelector((state) => state.user.alertPage);
    const isLoading =  useSelector((state) => state.user.isLoading);
    const currency = useSelector((state) => state.user.currency);

    const openCloseAlert = useCallback((isShowAlert, msg, page) => {
        dispatch(actions.openCloseAlert(isShowAlert, msg, page));
    }, [dispatch]);

    const openLoader = useCallback(() => {
        actions.openLoader(dispatch);
    }, [dispatch]);

    const closeLoader = useCallback(() => {
        actions.closeLoader(dispatch);
    }, [dispatch]);
  
    const setAppCurrency = useCallback((data) => {
        actions.setAppCurrency(dispatch, data);
    }, [dispatch]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const pageType = sessionStorage.getItem('type');
    const colorData = JSON.parse(sessionStorage.getItem('colour'));

    const handleModalOpen = () => {
        console.log(true)
        setOpenModal(true);
    };
    
    const handleModalClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        fetchPlanDetails();
    }, []);

    const goToPlanDetails = (plan_id) => {
        sessionStorage.setItem('plan_id', plan_id);
        sessionStorage.setItem('type', pageType);
        sessionStorage.setItem('colour', JSON.stringify(colorData));
        history.push('/plandetails');
    }

    const handleClickOpen = () => {
        openCloseAlert(true, (pageType == Languages.TopupESIM || sessionStorage.getItem('bundle') == Languages.TopUp) ? Languages.TopupAlert : Languages.PurchaseAlert, Languages.PlanDetails);
    };

    const handleClose = () => {
        openCloseAlert(false, "", "");
    };

    const fetchPlanDetails = async () => {
        openLoader();
        
        const planId = sessionStorage.getItem('plan_id');
        const activationId = sessionStorage.getItem('activation_id');
        console.log('PlanDetails ==>', planId, activationId, pageType);
        var data = {}
        var url = Constants.PlanDetails;
        if(pageType == Languages.TopupESIM) {
            data = {
                activation_id: activationId,
                plan_id: planId
            }
            url = Constants.PlanDetails;
        } else if(pageType == Languages.Local) {
            var telsim = sessionStorage.getItem('telsim');
            if(telsim == 'true'){
                data = {
                    plan_id: planId,
                    country_id: activationId
                };
                url = Constants.AddNewSIMTelsimPlanDetails;
            }else{
                data = {
                    country_id: activationId,
                    plan_id: planId
                }
                url = Constants.CountryPlanDetails;
            }
        } else if(pageType == Languages.Regional) {
            data = {
                region_id: activationId,
                plan_id: planId
            }
            url = Constants.RegionPlanDetails;
        } else if(pageType == Languages.GlobalSIM) {
            data = {
                plan_id: planId
            }
            url = Constants.GlobalPlanDetails;
        }
        
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(data, headers, url)
        console.log('PlanDetails ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                await setAppCurrency(response.data.app_currency != null && response.data.app_currency !== undefined ? response.data.app_currency : 'AU')
                setData(response.data);
                closeLoader();
            } else {
                closeLoader();
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            closeLoader();
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }
    

    const setData = async (plan) => {
        if(plan !== undefined && plan != null) {
            if(plan.PlanType !== undefined && plan.PlanType != null ){
                setPlanType(plan.PlanType);
            } else {
                setPlanType("");
            }
            if(plan.plans !== undefined && plan.plans != null){
                setPlanCoverage(plan.plans.plan_coverage);
                setPlanData(plan.plans.plan_data);
                setPlanValidity(plan.plans.plan_validity);
                setPlanAmount(plan.plans.plan_amount);
                setPlanImage(plan.plans.plan_image);
                //setPlanImage("http://tes.estest.link/wp-content/uploads/2023/05/eSIM-Canada-Plan-Card.png");
                setFlagImage(plan.plans.flag_image);
            }else{
                const pageType = sessionStorage.getItem('type');
                if(plan.PlanDetails !== undefined && plan.PlanDetails != null){
                    setPlanCoverage(pageType == Languages.Regional ? plan.PlanDetails.region_short_name : (plan.PlanDetails.country_name !== undefined && plan.PlanDetails.country_name != null) ? plan.PlanDetails.country_name : Languages.Global);
                    setPlanData(plan.PlanDetails.plan_data);
                    setPlanValidity(plan.PlanDetails.plan_validity);
                    setPlanAmount(plan.PlanDetails.plan_amount);
                    setPlanImage(plan.PlanDetails.plan_image)
                    //setPlanImage("http://tes.estest.link/wp-content/uploads/2023/05/eSIM-Canada-Plan-Card.png");
                    if(pageType == Languages.Local){
                        setFlagImage(plan.PlanDetails.flag_image);
                    }
                }
            }
            if(plan.plan_details !== undefined && plan.plan_details != null){
                setPlanDetails(plan.plan_details);
            }
            //if(pageType != Languages.Local && pageType != Languages.TopupESIM){
                if(plan.SupportedCountry !== undefined && plan.SupportedCountry != null) {
                    setSupportedCountry(plan.SupportedCountry || []);
                } else {
                    setSupportedCountry([]);
                }
            //}
            //if(pageType == Languages.Local || pageType == Languages.TopupESIM || sessionStorage.getItem('bundle') == Languages.TopUp){
                if(plan.SupportedNetworks !== undefined && plan.SupportedNetworks != null) {
                    setSupportedCountryNet(plan.SupportedNetworks || []);
                }else {
                    setSupportedCountryNet([]);
                }
            // }else{
            //     setSupportedCountryNet([]);
            // }
            if(plan.related_plans !== undefined && plan.related_plans != null){
                setRelatedPlanList(plan.related_plans || []);
            }else{
                setRelatedPlanList([]);
            }
            
        }
    }

    const createClientSecretKey = async () => {
        //setIsLoading(true)
        openLoader();
        const planId = sessionStorage.getItem('plan_id');
        var data = {};
        var url = '';
        if(pageType == Languages.TopupESIM || sessionStorage.getItem('bundle') == Languages.TopUp) {
            //const activationId = sessionStorage.getItem('activation_id');
            data = {
                activation_id: activation_id,
                plan_id: planId,
                plan_type: 2,
                platform: Languages.Platform
            }
            url = Constants.ClientSecretKey;
        }else{
            if(pageType == Languages.Local) {
                var telsim = sessionStorage.getItem('telsim');
                if(telsim == 'true'){
                    data = {
                        plan_id: planId
                    };
                    url = Constants.AddNewSIMTelsimPayment;
                }else{
                    data = {
                        plan_id: planId,
                        platform: Languages.Platform
                    };
                    url = Constants.AddNewSIMClientSecret;
                }
            }else{
                data = {
                    plan_id: planId,
                    platform: Languages.Platform
                };
                url = Constants.AddNewSIMClientSecret;
            }
            
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(data, headers, url)
        console.log('ClientSecretKey ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                confirmCardPayment(response.data);
            } else {
                closeLoader();
                //setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            closeLoader();
            //setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }

    const confirmCardPayment = async (data) => {
        try{
            var clientSecret = data.clientSecret;
            var session = data.session;
            var cardId = data.cardId;

            if (!stripe) {
                closeLoader();
                // Stripe.js has not yet loaded.
                return;
            }
            openLoader();
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: cardId,
            });
            
            if (result.error) {
            // Payment failed, display error to the user
                closeLoader();
                console.log(result.error.message);
                showMsg(result.error.message, Constants.SnackBarType.Error)
            } else if (result.paymentIntent.status === 'succeeded') {
                // Payment successful
                console.log('Payment successful!');
                var data = data = {
                    session: session
                };
                if(pageType == Languages.TopupESIM || sessionStorage.getItem('bundle') == Languages.TopUp) {
                    toTopUp(data, pageType, Constants.TopUp);
                }else {
                    if(pageType == Languages.Local){
                        var newTelsim = sessionStorage.getItem('telsim');
                        if(newTelsim == 'true'){
                            toTopUp(data, pageType, Constants.AddNewSIMTelsimActivation);
                        }else{
                            toTopUp(data, pageType, Constants.AddNewSIMActivation);
                        }

                    }else{
                        toTopUp(data, pageType, Constants.AddNewSIMActivation);
                    }
                }
            }
        }catch(e){
           console.log(e);
           closeLoader();
           //setIsLoading(false)
        }
    }

    const confirmCardPaymentTest = async () => {
        try{
            var clientSecret = 'pi_3NaajGE25dJEkkS20MpFu2CH_secret_aLExU5uGl3me7uMTaCzsd8gY5';
            var cardId = 'card_1NaZ89E25dJEkkS2DRut5AF6';

            if (!stripe) {
                closeLoader();
                // Stripe.js has not yet loaded.
                return;
            }
            openLoader();
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: cardId,
            });
            
            if (result.error) {
            // Payment failed, display error to the user
                closeLoader();
                console.log(result.error.message);
                showMsg(result.error.message, Constants.SnackBarType.Error)
            } else if (result.paymentIntent.status === 'succeeded') {
                // Payment successful
                console.log('Payment successful!');
                closeLoader();
            }
        }catch(e){
           console.log(e);
           closeLoader();
           //setIsLoading(false)
        }
    }

    const toTopUp = (data, type, url) => {
        onContinue(data, type, url)
    }

    const setPopUpCountryList = (data) => {
        return(
            <>
                {data.map(item => {
                    var supportedNetworks = []
                    if(item.country_list !== undefined && item.country_list != null){
                        if(pageType == Languages.GlobalSIM || PlanType == Languages.Global){
                            if(item.country_list.global_supported_networks !== undefined && item.country_list.global_supported_networks != null){
                                supportedNetworks = item.country_list.global_supported_networks || []
                            }else{
                                return
                            }
                        }else if(pageType == Languages.Regional || PlanType == Languages.Region){
                            if(item.country_list.region_supported_networks){
                                supportedNetworks = item.country_list.region_supported_networks || []
                            }else{
                                return
                            }
                        }else{
                            return
                        }
                    }else{
                        return
                    }
                    return(
                        <div >
                            <div className='networkBox col-lg-12'>
                                <div className='nCntryBox col-lg-12' style={{marginTop: 20}}>
                                    <img src={item.country_list.flag_image} style={{height: 30, width: 40, borderRadius: 5, border: '1px solid var(--secondary)'}} alt="" />
                                    <h6 style={{marginLeft: 10, marginTop: 5}}>{item.country_list.country_name}</h6>
                                    
                                </div>
                                <div className='col-lg-12'>
                                    {supportedNetworks.map(network=>(
                                    <div style={{marginTop: 3, marginLeft: 15}}>{network.network + ' (' + network.network_type + ')'}</div>
                                    ))}
                                </div>
                            </div>
                            
                        </div>
                    )
                })}
            </>
        )
    }

    return(
        <div>
            {isLoading == false ? (<div>
                <div id="plan-detail" className="plan-detail" style={{background: 'linear-gradient(to bottom, ' + colorData.top + ' 0%, ' + colorData.bottom + ' 100%)', paddingBottom: isMobile ? 0 : 35}}>
                    <div className="containers">
                        <div className="row img-row">
                            <div className="col-lg-7">
                                <div className="plan-card-img"style={{marginTop: isMobile ? -50 : 0}}>
                                    <img src={plan_image} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="content" style={{marginLeft: isMobile ? -40 : 0, marginRight: isMobile ? -40 : 0, marginTop: isMobile ? -50 : 0}}>
                                    <h3>{Languages.PlanDetails}</h3>
                                    <hr />
                                    <p><i className="fa fa-globe icon"></i> {Languages.Coverage} <span>{plan_coverage}</span></p>           
                                    <p><i className="fa fa-signal icon"></i> {Languages.Data} <span>{plan_data + ' ' + Languages.GB}</span></p>             
                                    {/* <p><i className="fa fa-calendar icon"></i> {Languages.Validity} <span>{plan_validity} {Languages.Days}</span></p>  */}
                                    <p><i className="fa fa-calendar icon"></i> {Languages.Validity} <span>{plan_validity} {plan_validity > 1 ? Languages.Days : "Day"}</span></p>
                                    <p><i className="fa fa-usd icon"></i> {Languages.Price} <span>{'$' + plan_amount}</span></p>
                                    <a><button type="button" className="plan-btn-2" onClick={handleClickOpen}>{currency} ${plan_amount} - {Languages.BuyNow}</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="brief" className="brief">
                    <div className="containers">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="plan-card-img" style={{padding: 5, marginLeft: isMobile ? -25 : 0, marginRight: isMobile ? -25 : 0}}>
                                    <h3>{Languages.SupportedCountries}</h3>
                                    {(pageType == Languages.Local || PlanType == Languages.Country ) ? (<div style={{marginTop: 20}}>
                                        <img src={flag_image} style={{height: 30}}className="img-fluid" alt="" /><span style={{paddingLeft: 10}}>{plan_coverage}</span>
                                    </div>) : (
                                        <>
                                            {(PlanType != Languages.Country && PlanType != "") ? (<div className='clist' style={{paddingLeft: isMobile ? 10: 50, paddingRight: isMobile ? 10: 50}}>
                                                {supportedCNetwork.map(item=>(
                                                <CountryItem 
                                                type={Languages.SupportedCountries}
                                                data={item}
                                                onClick={()=>{
                                                    //getPlanList(item.region_id, Languages.Regional);
                                                    //history.push('/plan-list')
                                                }}/>))}
                                            </div>) : (<div className='clist' style={{paddingLeft: isMobile ? 10: 50, paddingRight: isMobile ? 10: 50}}>
                                                {supportedCountry.map(item=>(
                                                <CountryItem 
                                                type={Languages.SupportedCountries}
                                                data={item}
                                                onClick={()=>{
                                                    //getPlanList(item.region_id, Languages.Regional);
                                                    //history.push('/plan-list')
                                                }}/>))}
                                            </div>)}
                                        </>)}
                                </div>
                            </div>
                            <div className="col-lg-7" style={{marginTop: isMobile ? 30 : 0}}>
                                <div className="content-brief" style={{marginLeft: isMobile ? -25 : 0, marginRight: isMobile ? -25 : 0, padding: isMobile ? 15 : 35}}>
                                    <h3>{Languages.AdditionalInformation} <span><a data-toggle="tooltip" title="The speed may vary depending on the location"><i className="bi bi-info-circle-fill"></i></a></span></h3>
                                    <hr />
                                    {planDetails.map(item=> (
                                        <>
                                            <strong><i className={item.specification_icon + " icon"}></i> {item.specification_name} </strong> 
                                            {(item.specification_name == Languages.PlanNetwork && supportedCNetwork.length > 0 && (PlanType == Languages.Country || PlanType == "")) ? (
                                                <>
                                                {supportedCNetwork.map(cnet =>(
                                                <p style={{marginBottom: 10}}>{cnet.network + ' (' + cnet.network_type + ')'}
                                                </p>))}
                                                </>
                                            ) : (<p onClick={()=>{
                                                if(item.specification_name == Languages.PlanNetwork){
                                                    if(pageType != Languages.Local && PlanType != Languages.Country){
                                                        handleModalOpen();
                                                    }
                                                }
                                            }}>{item.specification_name == Languages.PlanNetwork ? (pageType == Languages.Local || PlanType == Languages.Country) ? item.specification_value : Languages.CheckSupNetWork : item.specification_value}</p>)}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <h4 style={{marginTop: 40}}>{Languages.RelatedPlans}</h4>
                        <div className='row' style={{marginLeft: isMobile ? -35 : 30, marginRight: isMobile ? -35 : 30, marginTop: -40}}>
                            {relatedPlanList.map((item, i)=>(
                                <PlanItem
                                key={"" + i}
                                type={pageType}
                                colorInfo={colorData}
                                planData={item}
                                onShowDetails={()=> {
                                    goToPlanDetails(item.plan_id)
                                }}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>) : (
                <>
                    <Skeleton id="plan-detail" className="plan-detail" animation="wave" sx={{borderBottomLeftRadius: 40, borderBottomRightRadius: 40, marginTop: -15}} height={550}/>
                    <div id="brief" className="brief">
                        <div className="containers">
                            <div className="row" style={{marginTop: -150}}>
                            <Skeleton className="col-lg-5" animation="wave" sx={{borderRadius: 4}} height={300}/>
                            <Skeleton className="col-lg-7" animation="wave" sx={{borderRadius: 4}} height={300}/>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {alertPage == Languages.PlanDetails && (<Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {alertMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={()=>{
                        handleClose();
                        // confirmCardPaymentTest()
                        createClientSecretKey();
                        }}>Yes</Button>
                </DialogActions>
            </Dialog>)}
            <BootstrapDialog
                onClose={handleModalClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                {Languages.SupportedNetWorks}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className='row'>
                        {(PlanType != Languages.Country && PlanType != "") ? setPopUpCountryList(supportedCNetwork) : setPopUpCountryList(supportedCountry)}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleModalClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
      </div>
    )
}
export default PlanDetails;