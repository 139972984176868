/** @format */

import { persistCombineReducers } from "redux-persist";
import storage from 'redux-persist/lib/storage'
//import AsyncStorage from '@react-native-async-storage/async-storage';
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'

import { reducer as UserRedux } from "./UserRedux";

const config = {
  key: "root",
  storage: storage
};

export default persistCombineReducers(config, {
  user: UserRedux,
});
