const emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

const mobileRegex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
class Validate {
  constructor() {
    this.emailRegex = emailRegex;
    this.mobileRegex = mobileRegex;
  }

  isEmpty(...data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i]) return true;
    }
    return false;
  }

  isEmail(email) {
    return this.emailRegex.test(email);
  }
  isMobileNumber(mobileNumber) {
    if(mobileNumber.length == 10){
      return this.mobileRegex.test(mobileNumber);
    }else{
      return false;
    }    
  }
  // getCurrencyFormat = (price) => {
  //   let currency = currencyFormatter.format(price, {
  //       ...Constants.DefaultCurrency
  //   })
  //   return currency;
  // }
}

export default new Validate();
