import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';    
import swal from 'sweetalert';
import {Link, withRouter} from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import CustomerMobileHeader from "../Layout/CustomerMobileHeader";
import {getApiHeader, removeUserSession} from '../../utils/CommonFunctions';
import PageLoader from '../Loader/loader';
import Check from '../../assets/img/check.png'

import maleImg from '../../assets/media/users/male.png';
import {Skeleton, Snackbar, Box} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Colors, Languages, Constants } from "../../common";
import { UserAPI } from '../../services';
import ChangePassword from './ChangePassword';

const SERVER_URL    = process.env.REACT_APP_SERVER_URL; 
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyProfile = ({history}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [surname, setSurname] = useState("");
    const [sal, setSal] = useState("");
    const [address_full, setAddressFull] = useState("");
    const [email, setEmail] = useState("");
    const [showDetails, setShowDetails] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);


    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        setIsLoading(true)
        let headers 	= await getApiHeader();
        const response = await UserAPI.getData(headers, Constants.Profile)
        console.log('Profile ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                setIsLoading(false)
                setSal(response.data.profile.sal);
                setFirstName(response.data.profile.firstName);
                setSurname(response.data.profile.surname);
                setEmail(response.data.profile.email);
                //gotoHome(response);
            } else {
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    };

    // const setErrorMessages = (errorMessage,type)=>{
    //     let responseError = 'Internal server error';
    //     if (typeof(errorMessage) !== 'undefined' && errorMessage != null) {
    //         if(typeof errorMessage === 'object') {
    //             responseError = '';
    //             Object.keys(errorMessage).map(function(key, index) {
    //                 responseError+=errorMessage[key];
    //             });
    //         } else {
    //             responseError = errorMessage;
    //         }
    //     }
    //     swal(responseError, "", type);
    // }
 
    const handleLogout = async () => {  
        setIsLoading(true)
        let headers 	= await getApiHeader();
        const response = await UserAPI.getData(headers, Constants.Logout);
        console.log('logout ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                let p = new Promise((resolve, reject)=>{
                    removeUserSession(); 
                    resolve();
                })
                p.then(
                    ()=>{
                        setIsLoading(false)
                        window.location.href = "/login";
                    },
                    ()=>{
                        alert("Error in logout");
                    }
                )
                setIsLoading(false)
            }
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }
    
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };

    const onSubmit = async (password, newPassword, conPassword) => {
        setIsLoading(true)
        // gotodummyHome();
        const data = {
            password_old: password,
            password: newPassword,
            password_confirm: conPassword
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(data, headers, Constants.ChangePassword)
        console.log('ChangePassword ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                showMsg(response.message, Constants.SnackBarType.Success)
                setShowDetails(false);
                setShowPassword(false);
                setIsLoading(false)
            } else if(response.status == 422){
                var arr = [];
                arr = Object.keys(response.message).map(key=>response.message[key][0])
                setIsLoading(false)
                showMsg(arr, Constants.SnackBarType.Error)
            } else {
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }

    return (
        <>
            {/* {(isLoading === true) ? <PageLoader/> : null} */}
            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor common-class" id="kt_content"> 
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                    <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                    {message}
                    </Alert>
                </Snackbar>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                                <div className="kt-portlet">
                                    <div className="kt-portlet__head">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">{Languages.MyProfile}</h3>
                                        </div>
                                    </div> 
                                    {/* <CustomerMobileHeader/> */}
                                    <form className="kt-form kt-form--label-right" id="kt_profile_form">
                                        <input type="hidden" name="_token" value="ObupZ5m1zGiLOR7PsuEx5ioUiO7d83PmvaanZUVD"/>                        
                                        <div className="kt-portlet__body">
                                            <div className="kt-section kt-section--first">
                                                <div className="kt-section__body">
                                                    <div className="form-group">
                                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                                <div className='circleView centerView'>
                                                                    <img style={{height: 50, width: 50}} src={maleImg} alt="" />
                                                                </div>
                                                                <h4 style={{marginTop: 10}}>{sal} {firstName} {surname}</h4>  
                                                                <h6 style={{marginTop: 1}}>{email}</h6> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="authHideView" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                        <Box 
                                                        className='tabBoxShadow'
                                                        sx={{
                                                            borderRadius: 4,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            width: '70%',
                                                            justifyContent: 'space-around',
                                                            marginTop: 4
                                                        }}>
                                                            <Box sx={{
                                                                borderRadius: 4,
                                                                //height: '',
                                                                display: 'flex',
                                                                flex: 1,
                                                                marginRight: 1,
                                                                marginLeft: 1,
                                                                //backgroundColor: Colors.SummaryBack1,
                                                            }}>
                                                                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                                                    <div className='profile' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 20, marginRight: 20, padding: 20, borderColor: Colors.Primary, borderBottomWidth: 2}} onClick={()=>{ setShowDetails(!showDetails) }}>
                                                                        
                                                                        <h5>{Languages.Details}</h5>  
                                                                        {showDetails == false ? (<KeyboardArrowRightIcon />) : (<KeyboardArrowDownIcon />)}
                                                                        
                                                                    </div>
                                                                    {showDetails == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 20, marginRight: 20}}></div>)}
                                                                    {showDetails == true && (<div style={{marginLeft: 20, marginRight: 20, padding: 20}}>
                                                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10, borderColor: Colors.Primary, borderBottomWidth: 2}} onClick={()=>{ setShowDetails(!showDetails) }}>
                                                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 12}}>{Languages.Name}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 12, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.8, fontSize: 12, marginLeft: 5}}>{sal} {firstName} {middleName} {surname}</label>
                                                                            </div>
                                                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 12}}>{Languages.Email}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 12, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.8, fontSize: 12, marginLeft: 5}}>{email}</label>
                                                                            </div>
                                                                            {/* <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 12}}>{Languages.Address}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 12, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.8, fontSize: 12, marginLeft: 5}}>{address_full}</label>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>)}
                                                                    {showDetails == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 20, marginRight: 20}}></div>)}
                                                                    <div className='profile' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 20, marginRight: 20, padding: 20}} onClick={()=>{ setShowPassword(!showPassword) }}>
                                                                        <h5>{Languages.ChangePassword}</h5>
                                                                        {showPassword == false ? (<KeyboardArrowRightIcon />) : (<KeyboardArrowDownIcon />)} 
                                                                    </div>
                                                                    {showPassword == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 20, marginRight: 20}}></div>)}
                                                                    {showPassword == true && (<div style={{marginLeft: 20, marginRight: 20, padding: 20}}>
                                                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10, borderColor: Colors.Primary, borderBottomWidth: 2}}>
                                                                            <ChangePassword 
                                                                            isLoading={isLoading}
                                                                            showMsg={(msg)=>showMsg(msg, Constants.SnackBarType.Error)}
                                                                            onSubmit={onSubmit}
                                                                            />
                                                                        </div>
                                                                    </div>)}
                                                                    {showPassword == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 20, marginRight: 20}}></div>)}
                                                                    {/* <div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 20, marginRight: 20}}></div> */}
                                                                    <div className='profile' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 20, marginRight: 20, padding: 20}} onClick={handleLogout}>
                                                                        <h5>{Languages.Logout}</h5>  
                                                                        <KeyboardArrowRightIcon />
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                    <div className="hideMobileView" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                                        <Box 
                                                        className='tabBoxShadow'
                                                        sx={{
                                                            borderRadius: 4,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            width: '100%',
                                                            justifyContent: 'space-around',
                                                            marginTop: 4
                                                        }}>
                                                            <Box sx={{
                                                                borderRadius: 4,
                                                                //height: '',
                                                                display: 'flex',
                                                                flex: 1,
                                                                marginRight: 1,
                                                                marginLeft: 1,
                                                                //backgroundColor: Colors.SummaryBack1,
                                                            }}>
                                                                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10, borderColor: Colors.Primary, borderBottomWidth: 2}} onClick={()=>{ setShowDetails(!showDetails) }}>
                                                                        <h5>{Languages.Details}</h5>  
                                                                        {showDetails == false ? (<KeyboardArrowRightIcon />) : (<KeyboardArrowDownIcon />)}
                                                                    </div>
                                                                    {showDetails == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 5, marginRight: 5}}></div>)}
                                                                    {showDetails == true && (<div style={{marginLeft: 10, marginRight: 5, padding: 5}}>
                                                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10, borderColor: Colors.Primary, borderBottomWidth: 2}} onClick={()=>{ setShowDetails(!showDetails) }}>
                                                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 10}}>{Languages.Name}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 10, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.7, fontSize: 10, marginLeft: 5}}>{sal} {firstName} {middleName} {surname}</label>
                                                                            </div>
                                                                            <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 10}}>{Languages.Email}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 10, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.7, fontSize: 10, marginLeft: 5}}>{email}</label>
                                                                            </div>
                                                                            {/* <div style={{display: 'flex', flex: 1, flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                                                                                <label style={{display: 'flex', flex: 0.2, fontSize: 10}}>{Languages.Address}</label>
                                                                                <label style={{display: 'flex', flex: 0.1, fontSize: 10, marginLeft: 5}}>:</label>
                                                                                <label style={{display: 'flex', flex: 0.7, fontSize: 10, marginLeft: 5}}>{address_full}</label>
                                                                            </div>   */}
                                                                        </div>     
                                                                    </div>)}
                                                                    {showDetails == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 5, marginRight: 5}}></div>)}
                                                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10}} onClick={()=>{ setShowPassword(!showPassword) }}>
                                                                        <h5>{Languages.ChangePassword}</h5> 
                                                                        {showPassword == false ? (<KeyboardArrowRightIcon />) : (<KeyboardArrowDownIcon />)}
                                                                    </div>
                                                                    {showPassword == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 5, marginRight: 5}}></div>)}
                                                                    {showPassword == true && (<div style={{marginLeft: 10, marginRight: 5, padding: 5}}>
                                                                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10, borderColor: Colors.Primary, borderBottomWidth: 2}}>
                                                                            <ChangePassword 
                                                                            isLoading={isLoading}
                                                                            showMsg={(msg)=>showMsg(msg, Constants.SnackBarType.Error)}
                                                                            onSubmit={onSubmit}
                                                                            />
                                                                        </div>     
                                                                    </div>)}
                                                                    {showPassword == true && (<div style={{width: '95%', backgroundColor: Colors.Secondary, height: 1, marginLeft: 5, marginRight: 5}}></div>)}
                                                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 5, marginRight: 5, padding: 5, paddingTop: 10, paddingBottom: 10}} onClick={handleLogout}>
                                                                        <h5>{Languages.Logout}</h5>  
                                                                        <KeyboardArrowRightIcon />
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}

export default withRouter(MyProfile)