import React, { Component } from "react";
import { Route } from "react-router-dom";
import CustomerMobileHeader from "../Layout/CustomerMobileHeader";
import { Navigation, Footer, AccountSummary, MyProfile, MyPayments, ChangeBundle, PurchasePlan, TopUp, TopUpDetails, PlanList} from "..";
export default class RouteComponents extends Component {

constructor(props){
    super(props);
}

render()
{
    return <>
    <CustomerMobileHeader/>
    <div className="kt-grid kt-grid--hor kt-grid--root"> 
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"> 
                <Navigation/>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper"> 
                    {/* <Header/>         */}
                        <Route path="/" exact component={() => <AccountSummary />} />
						<Route path="/home" exact component={() => <AccountSummary />} />
						<Route path="/MyProfile" exact component={() => <MyProfile />} />
						<Route path="/MyPayments" exact component={() => <MyPayments />} />
						<Route path="/topup" exact component={() => <TopUp />} />
                        <Route path="/plandetails" exact component={() => <TopUpDetails />} />
						<Route path="/purchaseplan" exact component={() => <PurchasePlan />} />
                        <Route path="/plan-list" exact component={() => <PlanList />} />
                        <Route path="/change-plan" exact component={() => <ChangeBundle />} />
                    <Footer/>
                </div> 
            </div> 
        </div>
        <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="la la-arrow-up"></i>
        </div>
    </>
}
}