import * as React from 'react';
import { useState } from 'react';
import {Validate, Colors, Constants} from '../../common';
import { styled, useTheme } from '@mui/material/styles';
import { Switch, Paper, useMediaQuery, Grid, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const GraphView = ({balanceCnt}) => {

    //console.log('parseInt ==>', Math.round(2.85)); remainingCnt
    const setData = (id, gColor) => {
        var usedPerHeight = id * Constants.lowestPercentage;
        var remainingPerHeight = Constants.TotalPercentage - usedPerHeight;
        var usedHeight = usedPerHeight + '%';
        var remainingHeight = remainingPerHeight + '%';
        
        if(id == Constants.GraphCntTot) {
            return(
            <Box
            sx={{
                borderRadius: 2,
                display: 'flex',
                height: '100%',
                width: '18%',
                bgcolor: gColor
            }}>

            </Box>)
        } else {
            return(
                <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '18%',
                }}>
                    <Box
                    sx={{
                        borderRadius: 2,
                        display: 'flex',
                        height: remainingHeight,
                        width: '100%',
                        
                    }}>
                    </Box>
                    <Box
                    sx={{
                        borderRadius: 2,
                        display: 'flex',
                        height: usedHeight,
                        width: '100%',
                        bgcolor: gColor,//Colors.Secondary
                    }}>
    
                    </Box>
                </Box>
            )
        }
    }


    return(
        <Box 
        sx={{
            display: 'flex',
            height: 90,
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '50%',
        }}>
            {Constants.GraphCount.map(cnt => {
                if(cnt.id <= balanceCnt){
                    return setData(cnt.id, Colors.GraphBalance)
                } else {
                    return setData(cnt.id, Colors.Gray)
                }
            })}
            {/* <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '18%',
            }}>
                <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '80%',
                    width: '100%',
                    
                }}>

                </Box>
                <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '20%',
                    width: '100%',
                    bgcolor: Colors.GraphBalance,//Colors.Secondary
                }}>

                </Box>
            </Box>
            <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '18%',
            }}>
                 <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '60%',
                    width: '100%',
                    
                }}>

                </Box>
                <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '40%',
                    width: '100%',
                    bgcolor: Colors.GraphBalance,//Colors.Secondary
                }}>

                </Box>
            </Box>
            <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '18%',
            }}>
                 <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '40%',
                    width: '100%',
                    
                }}>

                </Box>
                <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '60%',
                    width: '100%',
                    bgcolor: Colors.GraphBalance,//Colors.Secondary
                }}>

                </Box>
            </Box>
            <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '18%',
            }}>
                 <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '20%',
                    width: '100%',
                    
                }}>

                </Box>
                <Box
                sx={{
                    borderRadius: 2,
                    display: 'flex',
                    height: '80%',
                    width: '100%',
                    bgcolor: Colors.Gray
                }}>

                </Box>
            </Box>
            <Box
            sx={{
                borderRadius: 2,
                display: 'flex',
                height: '100%',
                width: '18%',
                bgcolor: Colors.Gray
            }}>

            </Box> */}

        </Box>
    )
}

export default GraphView;