import * as React from 'react';
import { useState, useRef, useEffect, PureComponent } from 'react';
import {Link, withRouter} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../../common';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from "@mui/material/styles";

const PaymentTab = ({onChange}) => {

    const [isMethod, setIsMethod] = useState(true);
    
    return(
            <Box 
            className='tabBoxShadow'
            sx={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: Colors.Secondary,
            }}>
            <Box 
            sx={{
                borderRadius: 2,
                // borderTopRightRadius: 24,
                // borderBottomRightRadius: 24,
                height: 48,
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isMethod == true ? Colors.Primary : Colors.Secondary,
                // ':hover': {
                //     backgroundColor: Colors.InputText,
                //     opacity: [0.9, 0.8, 0.7],
                // },
            }}
            >
                <Button
                 style={{height: 48, display: 'flex',
                 flex: 1, justifyContent: 'center', 
                 fontWeight: isMethod == true ? 'bold' : 'normal',
                 fontSize: 11,
                 color: isMethod == true ? Colors.White : Colors.Black,
                 alignItems: 'center',}}
                    onClick={() => {
                        setIsMethod(true)
                        onChange(Languages.PaymentMethod)
                    }}
                    >
                        
                    {Languages.PaymentMethod}
                </Button>
            </Box>
            <Box sx={{
                borderRadius: 2,
                // borderTopLeftRadius: 24,
                // borderBottomLeftRadius: 24,
                height: 48,
                display: 'flex',
                flex: 1,
                backgroundColor: isMethod == false ? Colors.Primary : Colors.Secondary,
                // ':hover': {
                //     backgroundColor: Colors.InputText,
                //     opacity: [0.9, 0.8, 0.7],
                // },
            }}>
                <Button
                 style={{height: 48, display: 'flex',
                 flex: 1, justifyContent: 'center',
                 color: isMethod == false ? Colors.White : Colors.Black,
                 alignItems: 'center',
                 fontWeight: isMethod == false ? 'bold' : 'normal',
                 fontSize: 11
                }}
                    onClick={() => {
                        setIsMethod(false)
                        onChange(Languages.TransactionHistory)
                    }}
                    >
                    {Languages.TransactionHistory}
                </Button>
            </Box>
            </Box>
    );
}
export default PaymentTab;