import { UserAPI } from '../services';
import { Languages, Constants } from '../common';
import { getApiHeader } from '../utils/CommonFunctions';

const types = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    FETCH_ACCOUNTSUMMARY_SUCCESS: 'FETCH_ACCOUNTSUMMARY_SUCCESS',
    FETCH_DETAILS_PENDING: 'FETCH_DETAILS_PENDING',
    SET_ACTIVATION_ID: 'SET_ACTIVATION_ID',
    UPDATEUSER: "UPDATEUSER",
    SETTOKEN: "SETTOKEN",
    UPDATE_SHOW_MODAL: 'UPDATE_SHOW_MODAL',
    FETCH_DETAILS_FAILED: 'FETCH_DETAILS_FAILED',
    FETCH_COUNTRY_LIST: 'FETCH_COUNTRY_LIST',
    FETCH_PLAN_LIST: 'FETCH_PLAN_LIST',
    FETCH_REGION_LIST: 'FETCH_REGION_LIST',
    FETCH_GLOBAL_PLAN_LIST: 'FETCH_GLOBAL_PLAN_LIST',
    SEARCH_LIST: 'SEARCH_LIST',
    FETCH_TOPUP_PLAN_LIST: 'FETCH_TOPUP_PLAN_LIST',
    UPDATE_SHOW_MSG: 'UPDATE_SHOW_MSG',
    OPEN_CLOSE_ALERT: 'OPEN_CLOSE_ALERT',
    CLOSE_LOADER: 'CLOSE_LOADER',
    CLEAR_PLAN_LIST: 'CLEAR_PLAN_LIST',
    CLEAR_DATA: 'CLEAR_DATA',
    SET_APP_CURRENCY: 'SET_APP_CURRENCY',

}

export const actions = {
    login: (user, token) => {
        return {type: types.LOGIN, user, token}
    },
    logout: () => {
        return {type: types.LOGOUT}
    },
    updateUser: (user) => {
        return {type: types.UPDATEUSER, user}
    },
    setToken: (token) => {
        return {type: types.SETTOKEN, token}
    },
    setActivationId: (activationId, eSIMName) => {
        return({type: types.SET_ACTIVATION_ID, activationId, eSIMName})
    },
    setAppCurrency: async (dispatch, currency) => {
        dispatch({type: types.SET_APP_CURRENCY, currency});
    },
    updateShowModal: (isShowModal) => {
        return {type: types.UPDATE_SHOW_MODAL, isShowModal}
    },
    updateShowMsg: (isShowMsg) => {
        return {type: types.UPDATE_SHOW_MSG, isShowMsg}
    },
    openCloseAlert: (isShowAlert, msg, page) => {
        return {type: types.OPEN_CLOSE_ALERT, isShowAlert, alertMsg: msg, alertPage: page}
    },
    closeLoader: (dispatch) => {
        dispatch({type: types.CLOSE_LOADER});
    },
    openLoader: (dispatch) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
    },
    clearPlanList: (dispatch) => {
        dispatch({type: types.CLEAR_PLAN_LIST});
    },
    onSearch: (dispatch, countryList, regionList, searchTxt) => {
        var country_search = countryList.filter(item => (item.country_name.toLowerCase().includes(searchTxt.toLowerCase())));
        var region_search = regionList.filter(item => (item.region_name.toLowerCase().includes(searchTxt.toLowerCase())));
        var searchList = []
        var cpageType = {
            type: Languages.Local
        }
        country_search.map(item =>{

            searchList.push({
                ...item,
                ...cpageType
            })
        })
        var rpageType = {
            type: Languages.Regional
        }
        region_search.map(item =>{

            searchList.push({
                ...item,
                ...rpageType
            })
        })
        dispatch({type: types.SEARCH_LIST, searchList});
    },
    clearData: () => {
        return({type: types.CLEAR_DATA});
    },
    notAvailable: (dispatch) => {
        var ASummary = {
            eSIMDetails: null,
            eSIMMsg: "",
            eSIMQRCode: "",
            remData: 0, 
            remDays: 0,
            expiryDate: "",
            eSIMStatus: Languages.Installed,
            eSIMPlan: "",
            eSIMNo: "",
            balanceGCnt: 0,
            screen: 4
        }
        dispatch({type: types.FETCH_ACCOUNTSUMMARY_SUCCESS, ASummary});
    },
    getCountryList: async (dispatch) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        let headers = await getApiHeader();
        const response = await UserAPI.postData({}, headers, Constants.CountryList);
        console.log('CountryList ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                if(response.data !== undefined && response.data != null){
                    var countryList = response.data.country_list || []
                    var currency = response.data.app_currency != null && response.data.app_currency !== undefined ? response.data.app_currency : 'AU';
                    dispatch({type: types.FETCH_COUNTRY_LIST, countryList, currency});
                } else {
                    dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
                }
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        } else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }
    },
    getRegionList: async (dispatch) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        let headers = await getApiHeader();
        const response = await UserAPI.postData({}, headers, Constants.RegionList);
        console.log('RegionList ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                if(response.data !== undefined && response.data != null){
                    var regionList = response.data.region_list || []
                    dispatch({type: types.FETCH_REGION_LIST, regionList});
                } else {
                    dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
                }
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        } else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }
    },
    getGlobalPlanList: async (dispatch) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        let headers = await getApiHeader();
        const response = await UserAPI.postData({}, headers, Constants.GlobalPlanList);
        console.log('GlobalPlanList ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                if(response.data !== undefined && response.data != null){
                    var globalList = response.data.plans || []
                    var colorData = (response.data.theme_class !== undefined && response.data.theme_class != null) ? response.data.theme_class : Constants.ColorTheme;
                    var currency = (response.data.app_currency != null && response.data.app_currency !== undefined) ? response.data.app_currency : 'AU';
                    dispatch({type: types.FETCH_GLOBAL_PLAN_LIST, globalList, colorData, currency});
                } else {
                    dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
                }
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        } else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }
    },
    getTelsimPlanList: async(dispatch, id, pageType) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        var postData = {
            country_id: id
        };
        var pageInfo = {
            id: id,
            pageType: pageType,
            telsim: true
        }
        let headers = await getApiHeader();
        const response = await UserAPI.postData(postData, headers, Constants.GetTelsimPlans);
        console.log('TelsimPlanList ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                if(response.data !== undefined && response.data != null){
                    var planList = response.data.plans || [];
                    // var colorData = pageType == Languages.Local ? response.data.country_details : response.data.region_details;
                    var colorData = (response.data.theme_class !== undefined && response.data.theme_class != null) ? response.data.theme_class : Constants.ColorTheme;
                    var currency = (response.data.app_currency != null && response.data.app_currency !== undefined) ? response.data.app_currency : 'AU';
                    dispatch({type: types.FETCH_PLAN_LIST, planList, colorData, pageInfo, currency});
                } else {
                    dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
                }
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        } else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }

    },
    getPlanList: async (dispatch, id, pageType, startRange, endRange) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        var postData = {};
        var url = ''
        var pageInfo = {
            id: id,
            pageType: pageType,
            telsim: false
        }
        if(pageType == Languages.Local){
            postData = {
                country_id: id,
            }
            url = Constants.CountryPlanList
        }else if(pageType == Languages.Regional){
            postData = {
                region_id: id
            }
            url = Constants.RegionPlanList
        }
        let headers = await getApiHeader();
        const response = await UserAPI.postData(postData, headers, url);
        console.log('PlanList ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                if(response.data !== undefined && response.data != null){
                    var planList = response.data.plans || [];
                    var filterList = planList;
                    var currency = (response.data.app_currency != null && response.data.app_currency !== undefined) ? response.data.app_currency : 'AU';
                    // var colorData = pageType == Languages.Local ? response.data.country_details : response.data.region_details;
                    var colorData = (response.data.theme_class !== undefined && response.data.theme_class != null) ? response.data.theme_class : Constants.ColorTheme;
                    if(pageType == Languages.Local){
                        if(id == 1) {
                            var pList = filterList.filter(item=>(item.plan_validity >= startRange && item.plan_validity <= endRange))
                            planList = pList
                        }
                    }
                    dispatch({type: types.FETCH_PLAN_LIST, planList, colorData, pageInfo, currency});
                } else {
                    dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
                }
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        } else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }
    },
    getTopupPlanList: async (dispatch, id) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        const data = {
            activation_id: id,
            plan_type: "2"
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(data, headers, Constants.PlanList)
        console.log('TopupPlanList ==>', headers, data, response);
        if(response !== undefined){
            if(response.status == 200){
                var topup_message = response.data.topup_message;
                var topUPList = response.data.plans || [];
                var colorData = (response.data.theme_class !== undefined && response.data.theme_class != null) ? response.data.theme_class : Constants.ColorTheme;
                var currency = (response.data.app_currency != null && response.data.app_currency !== undefined) ? response.data.app_currency : 'AU';
                dispatch({type: types.FETCH_TOPUP_PLAN_LIST, topUPList, colorData, currency, topup_message});
            } else {
                dispatch({type: types.FETCH_DETAILS_FAILED, message: response.message});
            }
        }else {
            dispatch({type: types.FETCH_DETAILS_FAILED, message: Languages.ServerError});
        }
    },
    getAccountSummary: async (dispatch, id) => {
        dispatch({type: types.FETCH_DETAILS_PENDING});
        var ASummary = {
            eSIMDetails: null,
            eSIMMsg: "",
            eSIMQRCode: "",
            remData: 0, 
            remDays: 0,
            expiryDate: "",
            eSIMStatus: Languages.Installed,
            eSIMPlan: "",
            eSIMNo: "",
            balanceGCnt: 0,
            screen: 1
        }
        var postData = {
            activation_id: id
        }
        var currency = 'AU';
        let headers = await getApiHeader();
        const response = await UserAPI.postData(postData, headers, Constants.AccountSummary);
        console.log('getAccountSummary ==> ', response)
        if(response !== undefined) {
            if(response.status == 200){
                var screen = (response.data.screen !== undefined && response.data.screen != null) ? response.data.screen : 1;
                var graphData = (response.data.graph !== undefined && response.data.graph != null) ? response.data.graph : null;
                currency = (response.data.app_currency != null && response.data.app_currency !== undefined) ? response.data.app_currency : 'AU';
                var remDataCnt = 0;
                if(graphData != null) {
                    var totData = graphData.total_data;
                    var balaData = graphData.balance_data;
                    var perBData = (balaData / totData) * 100;
                    remDataCnt = Math.round(perBData / Constants.lowestPercentage);
                }
                var summaryData = (response.data.summary !== undefined && response.data.summary != null) ? response.data.summary : null;
                var esimData = (response.data.esim !== undefined && response.data.esim != null) ? response.data.esim : null;
                var esimMsg = "";
                var esimQrCode = "";
                var remData = 0;
                var remDays = 0;
                var expiry = "";
                var eSIMStatus = Languages.Installed;
                var current_plan = "";
                var eSIMNo = "";
                if(summaryData != null) {
                    remData = (summaryData.remaining_data != undefined && summaryData.remaining_data != null) ? summaryData.remaining_data : 0;
                    remDays = (summaryData.remaining_days != undefined && summaryData.remaining_days != null) ? summaryData.remaining_days : 0;
                    expiry = (summaryData.expiry != undefined && summaryData.expiry != null) ? summaryData.expiry : "";
                    eSIMStatus = (summaryData.esim_status != undefined && summaryData.esim_status != null) ? summaryData.esim_status : Languages.Installed;
                    current_plan = (summaryData.current_plan != undefined && summaryData.current_plan != null) ? summaryData.current_plan : "";
                    eSIMNo = (summaryData.esim_number != undefined && summaryData.esim_number != null) ? summaryData.esim_number : "";
                    if(eSIMStatus != Languages.Installed){
                        if(esimData != null) {
                            esimMsg = esimData.message;
                            esimQrCode = esimData.qr_code;
                        }
                    }
                }
                ASummary = {
                    eSIMDetails: esimData,
                    eSIMMsg: esimMsg,
                    eSIMQRCode: esimQrCode,
                    remData: remData, 
                    remDays: remDays,
                    expiryDate: expiry,
                    eSIMStatus: eSIMStatus,
                    eSIMPlan: current_plan,
                    eSIMNo: eSIMNo,
                    balanceGCnt: remDataCnt,
                    screen: screen
                }
                dispatch({type: types.FETCH_ACCOUNTSUMMARY_SUCCESS, ASummary, currency});
            }else{
                dispatch({type: types.FETCH_ACCOUNTSUMMARY_SUCCESS, ASummary, currency});
            }
            
        }else {
            dispatch({type: types.FETCH_ACCOUNTSUMMARY_SUCCESS, ASummary, currency});
        }
    },
}

const initialState = {
    user: null,
    token: null,
    ASummary: {
        eSIMDetails: null,
        eSIMMsg: "",
        eSIMQRCode: "",
        remData: 0, 
        remDays: 0,
        expiryDate: "",
        eSIMStatus: Languages.Installed,
        eSIMPlan: "",
        eSIMNo: "",
        balanceGCnt: 0,
        screen: 1
    },
    activationId: null,
    eSIMName: '',
    isLoading: false,
    isShowModal: false,
    countryList: [],
    regionList: [],
    globalList: [],
    planList: [],
    colorData: Constants.ColorTheme,
    errorMsg: "",
    showMsg: false,
    msgType: Constants.SnackBarType.Error,
    pageInfo: {
        id: 0,
        pageType: Languages.Local
    },
    showSearch: false,
    searchList: [],
    topUPList: [],
    isShowAlert: false,
    alertMsg: "",
    alertPage: "",
    currency: 'AU'
};

export const reducer = (state = initialState, action) => {
    const {type, user, token, activationId, ASummary, isShowModal, eSIMName, countryList, regionList, globalList, message, planList, colorData, pageInfo, searchList, topUPList, isShowMsg, isShowAlert, alertMsg, alertPage, currency, topup_message} = action;
    switch (type) {
        case types.SET_ACTIVATION_ID:
            return { ...state, activationId, eSIMName};
        case types.LOGIN:
            return { ...state, user, token};
        case types.SET_APP_CURRENCY: 
            return { ...state, currency};
        case types.LOGOUT:
            return { ...state, user: null, 
                token: null, activationId: null,
                eSIMName: '',
                ASummary: {
                    eSIMDetails: null,
                    eSIMMsg: "",
                    eSIMQRCode: "",
                    remData: 0, 
                    remDays: 0,
                    expiryDate: "",
                    eSIMStatus: Languages.Installed,
                    eSIMPlan: "",
                    eSIMNo: "",
                    balanceGCnt: 0,
                    screen: 1
                }, 
            };
        case types.CLEAR_DATA:
            return { ...state, user: null, 
                token: null, activationId: null,
                eSIMName: '',
                ASummary: {
                    eSIMDetails: null,
                    eSIMMsg: "",
                    eSIMQRCode: "",
                    remData: 0, 
                    remDays: 0,
                    expiryDate: "",
                    eSIMStatus: Languages.Installed,
                    eSIMPlan: "",
                    eSIMNo: "",
                    balanceGCnt: 0,
                    screen: 1
                }, 
                countryList: [],
                regionList: [],
                globalList: [],
                planList: [],
                searchList: [],
                topUPList: [],
                isLoading: false,
                topup_message: null,
                pageInfo: {
                    id: 0,
                    pageType: Languages.Local
                },
                showSearch: false,
            };
        case types.FETCH_COUNTRY_LIST: 
            return { ...state, countryList, currency, showSearch: false, isLoading: false };
        case types.FETCH_REGION_LIST: 
            return { ...state, regionList, showSearch: false, isLoading: false };
        case types.FETCH_GLOBAL_PLAN_LIST: 
            return { ...state, globalList, currency, colorData, showSearch: false, isLoading: false };
        case types.FETCH_PLAN_LIST: 
            return { ...state, planList, currency, colorData, pageInfo, isLoading: false };
        case types.FETCH_TOPUP_PLAN_LIST: 
            return { ...state, topUPList, currency, colorData, isLoading: false, topup_message};
        case types.SEARCH_LIST: 
            return { ...state, searchList, showSearch: true, isLoading: false };
        case types.UPDATEUSER: 
            return { ...state, user };
        case types.FETCH_DETAILS_PENDING: 
            return { ...state, isLoading: true };
        case types.CLOSE_LOADER: 
            return { ...state, isLoading: false };
        case types.FETCH_DETAILS_FAILED: 
            return { ...state, isLoading: false, errorMsg: message, showMsg: true, msgType: Constants.SnackBarType.Error };
        case types.UPDATE_SHOW_MSG: 
            return { ...state, isLoading: false, showMsg: isShowMsg };
        case types.OPEN_CLOSE_ALERT: 
            return { ...state, isShowAlert, alertMsg, alertPage };
        case types.FETCH_ACCOUNTSUMMARY_SUCCESS: 
            return { ...state, ASummary, isLoading: false };
        case types.CLEAR_PLAN_LIST: 
            return { ...state, isLoading: true, planList: [] };
        case types.SETTOKEN: 
            return { ...state, token };
        case types.UPDATE_SHOW_MODAL:
            return { ...state, isShowModal };
        default:
            return state;
    }
}

