import * as React from 'react';
import { useState, useRef, useEffect, PureComponent } from 'react';
import Moment from 'react-moment';
import {Validate, Colors, Constants, Languages} from '../../../common';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const TranHistoryItem = ({data, customerId, url}) => {
    const [isMethod, setIsMethod] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    
    return (
        <Paper
          sx={{
            p: 2,
            // margin: 'auto',
            width: isMobile ? '100%' : '40%',
            flexGrow: 1,
            marginBottom: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                  {'Date: '}<Moment format="DD-MM-YYYY hh:mm A">{data.created_at}</Moment>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {'Method: ' + data.method}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {'Status: '}
                    <label style={{fontSize: 11, color: data.payment_status == 'Unpaid' ? Colors.Gray : data.payment_status == 'Success' ? Colors.Green : Colors.Primary, fontWeight: 'bold'}}>{data.payment_status}</label>
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Typography sx={{ cursor: 'pointer' }} variant="body2">
                    Remove
                  </Typography>
                </Grid> */}
              </Grid>
                <Grid item>
                    <Typography variant="subtitle1" component="div">
                    {'USD ' + data.amount}
                    </Typography>
                    <Typography variant="subtitle1" component="div" style={{color: Colors.White}}>
                    {'akh '}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                    {(data.payment_status === 'Success') ? <a href={`${url}payments/invoice-download/${data.payment_id}/${customerId}` } target="_blank"><DownloadIcon sx={{ color: Colors.Primary, fontSize: 22 }}/></a> : null}
                    </Typography>
                </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
}
export default TranHistoryItem;