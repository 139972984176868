import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Colors, Constants, Languages} from '../../common';
import CustomerMobileHeader from "../Layout/CustomerMobileHeader";
import {getApiHeader} from '../../utils/CommonFunctions';
import { UserAPI } from '../../services';
import {Skeleton, Snackbar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PlanDetails from '../Plan/PlanDetails';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { actions } from '../../redux/UserRedux';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
// console.log(process.env.STRIPE_KEY);
const stripePromise = loadStripe(Constants.StripePublishKey);

const TopUpDetails = () => {

    const history = useHistory()
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);

    const isShowAlert = useSelector((state) => state.user.isShowAlert);
    const alertMsg = useSelector((state) => state.user.alertMsg);
    const alertPage = useSelector((state) => state.user.alertPage);

    const openCloseAlert = useCallback((isShowAlert, msg, page) => {
        dispatch(actions.openCloseAlert(isShowAlert, msg, page));
    }, [dispatch]);

    const openLoader = useCallback(() => {
        actions.openLoader(dispatch);
    }, [dispatch]);

    const closeLoader = useCallback(() => {
        actions.closeLoader(dispatch);
    }, [dispatch]);

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };

    const onContinueTopup = async (data, pageType, url) => {
        openLoader()
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(data, headers, url)
        console.log('TopUp ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                //showMsg(response.message, Constants.SnackBarType.Success)
                openCloseAlert(true, response.message, Languages.TopupDetails);
                closeLoader();
                //setIsLoading(false)
            } else {
                closeLoader();
                //setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            closeLoader()
            //setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }

    const handleClickOpen = (msg, page) => {
        openCloseAlert(true, msg, page);
    };

    const handleAlertClose = () => {
        openCloseAlert(false, "", "");
    };

    return(
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet" style={{marginTop: 20}}>
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">{sessionStorage.getItem('bundle') == Languages.TopUp ? Languages.TopupESIM : sessionStorage.getItem('type')}</h3>
                                    </div>
                                </div>
                                {/* <CustomerMobileHeader/> */}
                                <div>
                                    <Elements stripe={stripePromise}>
                                        <PlanDetails
                                        showMsg={showMsg}
                                        isLoading={isLoading}
                                        setIsLoading={(val)=>setIsLoading(val)}
                                        onContinue={onContinueTopup}/>
                                    </Elements>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alertPage == Languages.TopupDetails && (<Dialog
            open={isShowAlert}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleAlertClose}
            aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {alertMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        history.push('/home');
                        handleAlertClose();
                        }}>Ok</Button>
                </DialogActions>
            </Dialog>)}
        </div>
    );
}
export default TopUpDetails;