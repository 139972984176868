import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSession, getApiHeaderGuest } from '../../utils/CommonFunctions';
import { Link, withRouter} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LogoImg from '../../assets/media/esim/esim_logo.png'
import LogoWhite from '../../assets/img/logowhite.png';
import {Skeleton, Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Login from './Login';
import Register from './Register';
import { UserAPI } from '../../services';
import {Validate, Colors, Constants, Languages} from '../../common';
import { actions } from '../../redux/UserRedux';
import bac from  '../../assets/img/background.png'

const headers 		= getApiHeaderGuest();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Auth = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLogin, setIsLogin] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error); 
    const [rFirstName, setRFirstName] = useState("");
    const [rLastName, setRLastName] = useState("");
    const [rUserName, setRUserName] = useState("");
    const [rPassword, setRPassword] = useState("");
    const [rCPassword, setRCPassword] = useState("");
    const [rSessionId, setRSessionId] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [sendOTP, setSendOTP] = useState(false)


    const clearData = useCallback(() => {
        dispatch(actions.clearData());
    }, [dispatch]);

    const notAvailable = useCallback(() => {
        actions.notAvailable(dispatch);
    }, [dispatch]);

    const setActivationId = useCallback((activationId, eSIMName) => {
        dispatch(actions.setActivationId(activationId, eSIMName));
    }, [dispatch]);

    const onEmailEditHandle = (event) => {
        setEmail(event.target.value);
    }

    const onPasswordEditHandle = (event) => {
        setPassword(event.target.value);
    }

    const validateForm = () => {
        console.log(email, password);
        if(Validate.isEmpty(email, password)){
            return Languages.LoginError;
        }else if(!Validate.isEmail(email)){
          return Languages.EmailError;
        }
        return undefined;
    }

    const getActivations = async (headers) => {
        setIsLoading(true)
        const response = await UserAPI.postData({}, headers, Constants.UserActivations)
        console.log('UserActivations ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                if(response.data.activations.length > 0){
                    //setIsLoading(false);
                    let data 		= response.data.activations; 
                    let esim_Name = '';
                    let activation_Id = 0
                    if(data.length > 0){
                        esim_Name = data[0].country_name + ' - ' + data[0].iccid;
                        activation_Id = data[0].activation_id
                    }
                    await setActivationId(activation_Id, esim_Name)  
                }else{
                    await notAvailable();
                }
                
            } else {
                //setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Warning)
            }
        }else {
            //setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Warning);
        }
    };

    const gotoHome = async (response) => {
        const header    =   await {
            'Authorization': 'Bearer ' + response.data.token,
            'api-token': Constants.ApiToken,
            'device-type': 'Web'
        };
        getActivations(header);
        setIsLoading(true)
        let p = new Promise((resolve, reject)=>{
            setUserSession(response.data.token, response.data.user);
            resolve();
        })
        p.then(
            ()=>{
                //this.props.history.push("/home");
                setIsLoading(false)
                setSendOTP(false);
                window.location.href = "/home";
            },
            ()=>{
                setIsLoading(false)
                showMsg("Error in Login", Constants.SnackBarType.Warning)
            }
        )
    }

    //dummy data
    const gotodummyHome = (response) => {
        let p = new Promise((resolve, reject)=>{
            setUserSession(Constants.SampleToken, Constants.SampleUser);
            resolve();
        })
        p.then(
            ()=>{
                //this.props.history.push("/home");
                setIsLoading(false)
                window.location.href = "/home";
            },
            ()=>{
                setIsLoading(false)
                showMsg("Error in Login", Constants.SnackBarType.Warning)
            }
        )
    }

    const onLogin = async (username, password) => {
        setIsLoading(true)
        await clearData();
        // gotodummyHome();
        const data = {
            username: username,
            password: password
        }
        const response = await UserAPI.postData(data, headers, Constants.Login)
        console.log('login ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                showMsg(response.message, Constants.SnackBarType.Success)
                gotoHome(response);
            } else if(response.status == 422){
                var arr = [];
                arr = Object.keys(response.message).map(key=>response.message[key][0])
                setIsLoading(false)
                showMsg(arr, Constants.SnackBarType.Warning)
            }else{
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Warning)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Warning);
        }
    }

    const onLoginPressHandle = async () => {
        const error = validateForm();
        if(error) return showMsg(error, Constants.SnackBarType.Warning);
        onLogin(email, password);
    }

    const onVerifyEmailToHome = async () => {
        setIsLoading(true)
        var data = {
            firstname: rFirstName,
            lastname: rLastName,
            email: rUserName,
            password: rPassword,
            password_confirmation: rCPassword,
            session_id: rSessionId
        }
        var url = Constants.VerifyeSIM;
        const response = await UserAPI.postData(data, headers, url)
        if(response !== undefined){
            if(response.status == 200){
                showMsg(response.message, Constants.SnackBarType.Success);
                gotoHome(response);
                setIsLoading(false)
            } else if(response.status == 422){
                var arr = [];
                arr = Object.keys(response.message).map(key=>response.message[key][0])
                setIsLoading(false)
                showMsg(arr, Constants.SnackBarType.Warning)
            } else {
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Warning)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Warning);
        }
    }

    const onRegister = async (firstname, lastname, username, password, conPass, otp) => {
        setIsLoading(true)
        await clearData();
        var data = {
            firstname: firstname,
            lastname: lastname,
            email: username,
            password: password,
            password_confirmation: conPass
        }
        var url = Constants.Register;
        if(sendOTP == true && username == rUserName && password == rPassword){
            data = {
                email: rUserName,
                otp: otp,
                session_id: rSessionId
            };
            url = Constants.VerifyRegisterOTP;
        }
        const response = await UserAPI.postData(data, headers, url)
        console.log('Register ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                showMsg(response.message, Constants.SnackBarType.Success);
                if(sendOTP == false){
                    setRSessionId((response.data.session_id !== undefined && response.data.session_id != null) ? response.data.session_id : null)
                    setRCPassword(conPass);
                    setRFirstName(firstname);
                    setRLastName(lastname);
                    setRPassword(password);
                    setRUserName(username);
                    setIsVerified(true);
                    setSendOTP(true);
                    setIsLoading(false)
                } else {
                    onVerifyEmailToHome();
                }
                
                
                //gotoHome(response);
            } else if(response.status == 422){
                var arr = [];
                arr = Object.keys(response.message).map(key=>response.message[key][0])
                setIsLoading(false)
                showMsg(arr, Constants.SnackBarType.Warning)
            } else {
                setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Warning)
            }
        }else {
            setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Warning);
        }
    }

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }
    
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };
    
    return(
        <div className="kt-grid kt-grid--ver kt-grid--root login">
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'top'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }} style={{fontSize: sendOTP == true ? 16 : 14}}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2 backImage login" id="kt_login_v2">
                <div className="kt-grid__item  kt-grid  kt-grid--ver  kt-grid__item--fluid loginCenterView">
                    <div>
                        <div className='authHideView' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
                            <img style={{height: 70, width: 150}} src={LogoWhite} alt="" />
                        </div>
                        
                        <div className='hideMobileView' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
                            <img style={{height: 70, width: 150}} src={LogoImg} alt="" />
                        </div>
                        
                        {/* authHideView */}
                        <div className='boxView authHideView'> 
                        
                            <form className="loginForm">
                            
                            {isLoading == false ? (<div className='headerView rowView'>
                                    <div className='headerItem centerView pointer' style={{backgroundColor: isLogin == true ? Colors.White : Colors.Secondary, fontWeight: isLogin == true ? 600 : 400}} onClick={()=>{
                                        setIsLogin(true)
                                        setSendOTP(false)
                                        }}>
                                        {Languages.Login}
                                    </div>
                                    
                                    <div className='headerItem centerView pointer' style={{backgroundColor: isLogin == false ? Colors.White : Colors.Secondary, fontWeight: isLogin == false ? 600 : 400}} onClick={()=>{
                                        setIsLogin(false)
                                        setSendOTP(false)
                                        }}>
                                        {Languages.Register}
                                    </div>

                                </div>) : (
                                    <div className='headerView rowView' style={{borderTopLeftRadius: 10, borderTopRightRadius: 10,backgroundColor: Colors.White}}>
                                        <Skeleton animation="wave" sx={{ marginLeft: 1, marginTop: 1}} variant="rectangular" width={225} height={48}/>
                                        <Skeleton animation="wave" sx={{ marginLeft: 1, marginRight: 1, marginTop: 1}} variant="rectangular" width={225} height={48}/>
                                    </div>
                                )}
                               { isLogin == true ? (
                                <Login 
                                isLoading={isLoading}
                                showMsg={(msg)=>showMsg(msg, Constants.SnackBarType.Warning)}
                                onLogin={onLogin}/>
                                ) : (
                                <Register 
                                isLoading={isLoading}
                                onRegister={onRegister}
                                showMsg={(msg)=>showMsg(msg, Constants.SnackBarType.Warning)}
                                sendOTP={sendOTP}/>
                               )}
                            </form>
                        </div>
                        <div className='mBoxView hideMobileView'> 
                            <form className="mLoginForm">
                                {isLoading == false ? (<div className='mHeaderView rowView'>
                                    <div className='mHeaderItem centerView pointer' style={{backgroundColor: isLogin == true ? Colors.White : Colors.Secondary, fontWeight: isLogin == true ? 600 : 400}} onClick={()=>{
                                        setIsLogin(true)
                                        setSendOTP(false)
                                        }}>
                                        {Languages.Login}
                                    </div>
                                    <div className='mHeaderItem centerView pointer' style={{backgroundColor: isLogin == false ? Colors.White : Colors.Secondary, fontWeight: isLogin == false ? 600 : 400}} onClick={()=>{
                                        setIsLogin(false)
                                        setSendOTP(false)
                                        }}>
                                        {Languages.Register}
                                    </div>

                                </div>) : (
                                    <div className='mHeaderView rowView' style={{backgroundColor: Colors.White}}>
                                    <Skeleton animation="wave" sx={{borderRadius: 1, marginLeft: 1, marginTop: 1, width: '48%'}} variant="rectangular" height={48}/>
                                    <Skeleton animation="wave" sx={{borderRadius: 1, marginLeft: 1, marginRight: 1, marginTop: 1, width: '48%'}} variant="rectangular" height={48}/>
                                </div>
                                )}
                               { isLogin == true ? (
                                <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
                                    <div className="form-group" style={{marginTop: 40}}>
                                        {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 40}}>
                                            {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                                            <input name="email" type='email' className="form-control" placeholder={Languages.Email} value={email} onChange={onEmailEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: Colors.InputText, height: 48, marginRight: 40, marginLeft: 40,}}/>
                                        </div>) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                                        )}
                                        {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
                                    </div>
                                    <div className="form-group">
                                       {isLoading == false ? ( <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                                            {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                                            <input type={showPassword == false ? "password" : 'text'} name="password" className="form-control" placeholder={Languages.Password} value={password} onChange={onPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: Colors.InputText, height: 48, marginLeft: 40,}}/>
                                            <div className='pointer' onClick={()=>{setShowPassword(!showPassword)}} style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48}}>{showPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                                        </div>) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                                        )}
                                        {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
                                    </div>
                                    
                                    
                                    <div className="form-group">
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                                            {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                                            <label className="mlinkView" style={{justifyContent:'end'}}>
                                                
                                            </label> 
                                            <label className="mlinkView pointer" style={{justifyContent:'end'}}>
                                                {isLoading == false ? (<Link to="/ForgotPassword" style={{color: Colors.Black}}>
                                                    {Languages.ForgotPassword}
                                                </Link>) : (
                                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', display: 'flex', flex: 1 }} />
                                                )}
                                            </label> 
                                        </div>
                                        {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
                                    </div>
                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: -20}}>
                                        {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill pointer" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40}} onClick={onLoginPressHandle}>{Languages.Login}</button>
                                        ) : (
                                            <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                                        )}
                                    </div>
                                    <div className="form-group" >
                                    <label className="form-check-label authInfo"  style={{color: Colors.White}}>{Languages.RegisterInfo1}
                                        <Link to="" className="kt-link kt-link--brand" style={{color: Colors.White}}>
                                            {Languages.TermsCondition}
                                        </Link>{" and "}
                                        <Link to="" className="kt-link kt-link--brand" style={{color: Colors.White}}>
                                            {Languages.PrivacyPolicy}
                                        </Link>
                                        {Languages.RegisterInfo2}
                                    </label> 
                                    </div>
                                </div>
                               
                                ) : (
                                <Register 
                                isLoading={isLoading}
                                onRegister={onRegister}
                                showMsg={(msg)=>showMsg(msg, Constants.SnackBarType.Warning)}
                                sendOTP={sendOTP}/>
                               )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Auth;