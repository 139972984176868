import * as React from 'react';
//import React, { Component } from "react";
import { install } from "resize-observer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "./components/Pages/Auth";
import {getToken} from './utils/CommonFunctions';
import RouteComponents from "./components/Routes/RouteComponents";
import {Routes} from './components/Routes/Routes';
import ForgotPassword from "./components/Pages/ForgotPassword";

install();

const Router = () => {

    return (
        <>
            <BrowserRouter>
                {/* basename={process.env.REACT_APP_BASE_NAME} */}
                <Switch>
                    {(getToken() == null) ? 
                    <Route exact path="/" component={Auth} /> 
                    : 
                    <Route exact path="/" component={RouteComponents} />
                    }
                    <Route exact path="/Login" component={Auth} />
                    <Route exact path="/ForgotPassword" component={ForgotPassword} />
                    <Routes exact path="/app" component={RouteComponents} />
                    <Routes exact path="/home" component={RouteComponents} />
                    <Routes exact path="/MyProfile" component={RouteComponents} />
                    <Routes exact path="/MyPayments" component={RouteComponents} />
                    <Routes exact path="/topup" component={RouteComponents} />
                    <Routes exact path="/plandetails" component={RouteComponents} />
                    <Routes exact path="/purchaseplan" component={RouteComponents} />
                    <Routes exact path="/plan-list" component={RouteComponents} />
                    <Routes exact path="/change-plan" component={RouteComponents} />
                </Switch>
            </BrowserRouter>
        </>
    )
}
export default Router;