import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Link, withRouter} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Skeleton, Stack} from '@mui/material';

const Login = ({isLoading, onLogin, showMsg}) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLogin, setIsLogin] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    
    const onEmailEditHandle = (event) => {
        setEmail(event.target.value);
    }

    const onPasswordEditHandle = (event) => {
        setPassword(event.target.value);
    }

    const validateForm = () => {
        if(Validate.isEmpty(email, password)){
            return Languages.LoginError;
        }else if(!Validate.isEmail(email)){
          return Languages.EmailError;
        }
        return undefined;
    }

    const onLoginPressHandle = () => {
        const error = validateForm();
        if(error) return showMsg(error);
        onLogin(email, password);
    }
    
    return(
        <div className='loginForm' style={{backgroundColor: Colors.White, marginTop: -40, paddingTop: 40, paddingBottom: 30}}>
            <div className="form-group" style={{marginTop: 40}}>
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 40}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="email" type="text" className="form-control" placeholder={Languages.Email} value={email} onChange={onEmailEditHandle} style={{borderWidth: 0, borderRadius: 5, backgroundColor: '#e5e5e5', height: 48, marginRight: 40, marginLeft: 40,flex: 1}}/>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className="form-group">
                {isLoading == false ? (<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                    {/* <i class="fa fa-envelope loginIcon" style={{color: '#D6060D'}}></i> */}
                    <input name="password" type={showPassword == false ? "password" : 'text'} className="form-control" placeholder={Languages.Password} value={password} onChange={onPasswordEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#e5e5e5', height: 48, marginLeft: 40}}/>
                    <div onClick={()=>{setShowPassword(!showPassword)}} className='pointer' style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 48, backgroundColor: '#e5e5e5', marginRight: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48}}>{showPassword == false ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}</div>
                </div>) : (
                    <Skeleton animation="wave" sx={{borderRadius: 1 ,marginLeft: 5, marginRight: 5, display: 'flex', flex: 1}} variant="rectangular" height={48}/>
                )}
                {/* {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })} */}
            </div>
            <div className='rowView'>
                
            </div>
            <div className="headerView rowView" style={{marginLeft: 40, marginRight: 40}}>
                <label className="linkView">
                    {/* <Link to="/ResetPassword" style={{color: 'black', textAlign: 'end'}}>
                        {Languages.ForgotPassword}
                    </Link> */}
                </label> 
                <label className="linkView pointer" style={{justifyContent:'end'}}>
                    {isLoading == false ? (<Link to="/ForgotPassword" style={{color: Colors.Black}}>
                        {Languages.ForgotPassword}
                    </Link>) : (
                        <Skeleton animation="wave" variant="text" sx={{ display: 'flex', flex: 1 }} />
                    )}
                </label> 
            </div>
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
                {isLoading == false ? (<button type="button" className="btn btn-brand btn-elevate btn-pill pointer" style={{height: 48, borderRadius: 5, flex: 1, alignItems: 'center', justifyContent: 'center',marginRight: 40, marginLeft: 40, backgroundColor: Colors.Primary}} onClick={onLoginPressHandle}>{Languages.Login}</button>
                ) : (
                    <Skeleton sx={{ borderRadius: 1, marginLeft: 4, marginRight: 4, display: 'flex', flex: 1, bgcolor: Colors.Primary}} variant="rectangular" height={48}/>
                )}
            </div>
        </div>
    );
}
export default Login;