import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/style.css';

import './assets/css/skins/header/base/brand.css';
import './assets/css/skins/header/menu/light.css';
import './assets/css/skins/brand/brand.css';
import './assets/css/skins/aside/light.css';
import './assets/css/custom.css';
import './assets/css/plans.css'; 
import './assets/css/pages/login/login.css';  
import './assets/css/pages/login/login-v2.css'; 
import './assets/css/pages/invoice/invoice-v1.css';
import './assets/css/pages/invoice/invoice-v2.css';   

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
