import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import CustomerMobileHeader from "../Layout/CustomerMobileHeader";
import { styled, useTheme } from '@mui/material/styles';
import {Skeleton, Snackbar, useMediaQuery, Tabs, Tab, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import CountryItem from './eSIM/CountryItem';
import PlanItem from '../Plan/PlanItem';
import { actions } from '../../redux/UserRedux';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {/* {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null} */}
      </DialogTitle>
    );
}

const ChangeBundle = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const [searchTxt, setSearchTxt] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const [openModal, setOpenModal] = React.useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const countryList = useSelector((state) => state.user.countryList);
    const regionList = useSelector((state) => state.user.regionList);
    const isLoading =  useSelector((state) => state.user.isLoading);
    const globalList = useSelector((state) => state.user.globalList);
    const colorData = useSelector((state) => state.user.colorData);
    const searchList = useSelector((state) => state.user.searchList);
    const showSearch = useSelector((state) => state.user.showSearch);
    const open = useSelector((state) => state.user.showMsg);
    const message = useSelector((state) => state.user.errorMsg);
    const msgType = useSelector((state) => state.user.msgType);

    useEffect(() => {
        getData();
    }, []);

    const updateShowMsg = useCallback((val) => {
        dispatch(actions.updateShowMsg(val));
    }, [dispatch]);

    const getCountryList = useCallback(() => {
        actions.getCountryList(dispatch);
    }, [dispatch]);

    const getRegionList = useCallback(() => {
        actions.getRegionList(dispatch);
    }, [dispatch]);

    const getGlobalPlanList = useCallback(() => {
        actions.getGlobalPlanList(dispatch);
    }, [dispatch]);

    const getPlanList = useCallback((id, pageType, startRange, endRange) => {
        actions.getPlanList(dispatch, id, pageType, startRange, endRange);
    }, [dispatch]);

    const clearPlanList = useCallback(() => {
        actions.clearPlanList(dispatch);
    }, [dispatch]);

    const getTelsimPlanList = useCallback((id, pageType) => {
        actions.getTelsimPlanList(dispatch, id, pageType);
    }, [dispatch]);

    const onSearch = useCallback((countryList, regionList, searchTxt) => {
        actions.onSearch(dispatch, countryList, regionList, searchTxt);
    }, [dispatch]);

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };
    

    const onSearchEditHandle = (event) => {
        if(event.target.value.length >= 3){
            onSearch(countryList, regionList, event.target.value)
        }else if(event.target.value.length < 1){
            getData()
        }
        setSearchTxt(event.target.value);
    }

    const getData = async () => {
        await getCountryList();
        await getRegionList();
        await getGlobalPlanList();
    }

    // //show messages
    // const showMsgs = (type) => {
    //     setMessage(message);
    //     setMsgType(type);
    //     setOpen(true);
    // }

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        updateShowMsg(false)
        //setOpen(false);
    };

    const onSearchPressHandle = async () => {
        if(showSearch == true){
            setSearchTxt("")
            getData();
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    };

    const fetchPlanDetails = async (plan_id) => {
        sessionStorage.setItem('plan_id', plan_id);
        sessionStorage.setItem('type', Languages.GlobalSIM);
        sessionStorage.setItem('colour', JSON.stringify(colorData));
        history.push('/plandetails');
    }
    
    return(
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet" style={{marginTop: 20}}>
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        {isLoading == false ? (
                                        <h3 className="kt-portlet__head-title">{Languages.TopupESIM}</h3>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                        )}
                                    </div>
                                </div>
                                {/* <CustomerMobileHeader/> */}
                                {isLoading == false ? (<div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                                    <h5 >{Languages.HeaderTxt1}</h5>
                                    <h2 style={{fontWeight: 'bold', marginTop: 5}}>{Languages.HeaderTopupTxt}</h2>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: isMobile ? '95%' : '60%'}}>
                                        <input name="search" type='text' className="form-control" placeholder={Languages.SearchPlaceHolder} value={searchTxt} onChange={onSearchEditHandle} style={{borderWidth: 0, borderRadius: 5, borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#e5e5e5', height: 40}}/>
                                        <div onClick={onSearchPressHandle} style={{borderWidth: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, height: 40, backgroundColor: Colors.Primary, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48}}>{showSearch == false ?(<SearchIcon sx={{ color: Colors.White }}/>) : (<CloseIcon sx={{ color: Colors.White }}/>)}</div>
                                    </div>
                                    {showSearch == false && (<Tabs value={tabValue} onChange={handleTabChange} aria-label="disabled tabs example" sx={{ marginTop: 2}}>
                                        <Tab label={Languages.Local} />
                                        <Tab label={Languages.Regional} />
                                        <Tab label={Languages.GlobalSIM} />
                                    </Tabs>)}
                                    
                                </div>): (<div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                                   
                                    <Skeleton animation="wave" sx={{borderRadius: 1}} width={200} height={30} variant="text"/>
                                    <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} height={35}  variant="text"/>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: isMobile ? '95%' : '60%'}}>
                                    <Skeleton animation="wave" sx={{borderRadius: 1}} width={'100%'} height={60}/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, width: isMobile ? '95%' : '40%'}}>
                                        <Skeleton animation="wave" sx={{borderRadius: 1}} width={'100%'} height={40}/>
                                    </div>
                                    
                                </div>

                                )}
                                {showSearch == true && (<div className='row' style={{marginLeft: isMobile ? 15 : 80, marginRight: isMobile ? 15 : 80, marginTop: 20}}>
                                    {searchList.map(item=>(
                                        <CountryItem 
                                        data={item}
                                        type={item.type}
                                        onClick={()=>{
                                            clearPlanList();
                                            getPlanList(item.type == Languages.Local ? item.country_id : item.region_id, item.type, 1, 30);
                                            history.push('/plan-list')
                                        }}/>
                                    ))}
                                </div>)}
                                {showSearch == false && (<>
                                    {isLoading == false ? (<>
                                        {tabValue == 0 && (<div className='row' style={{marginLeft: isMobile ? 15 : 80, marginRight: isMobile ? 15 : 80, marginTop: 20}}>
                                            {countryList.map(item=>(
                                                <CountryItem 
                                                data={item}
                                                type={Languages.Local}
                                                onClick={()=>{
                                                    clearPlanList();
                                                    getPlanList(item.country_id, Languages.Local, 1, 30);
                                                    history.push('/plan-list')
                                                }}/>
                                            ))}
                                        </div>)}
                                        {tabValue == 1 && (<div className='row' style={{marginLeft: isMobile ? 15 : 100, marginRight: isMobile ? 15 : 100, marginTop: 20}}>
                                            {regionList.map(item=>(
                                                <CountryItem 
                                                type={Languages.Regional}
                                                data={item}
                                                onClick={()=>{
                                                    clearPlanList();
                                                    getPlanList(item.region_id, Languages.Regional, 0, 0);
                                                    history.push('/plan-list')
                                                }}/>
                                            ))}
                                        </div>)}
                                        {tabValue == 2 && (<div className='row' style={{marginLeft: isMobile ? 15 : 50, marginRight: isMobile ? 15 : 50, marginTop: 20}}>
                                            {globalList.map((item, i)=>(
                                                <PlanItem
                                                key={"" + i}
                                                type={Languages.GlobalSIM}
                                                colorInfo={colorData}
                                                planData={item}
                                                onShowDetails={()=> {
                                                    fetchPlanDetails(item.plan_id)
                                                }}/>
                                            ))}
                                        </div>)}
                                    </>) : (
                                    <div className='row' style={{marginLeft: isMobile ? 15 : 80, marginRight: isMobile ? 15 : 80, marginTop: 20}}>
                                    {Constants.ButtonList.map(item=>(
                                        <div className={'col-lg-4'}>
                                            {/* <div className='col-lg-4 cntryBox' style={{marginTop: 30, padding: 0,margin: 0}}> */}
                                            <Skeleton className='cntryBox' animation="wave" sx={{borderRadius: 1}} width={'100%'} height={80}/> 
                                            
                                        </div>
                                       
                                    ))}
                                    </div>)}
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={handleModalClose}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                {Languages.TitleInfoDialog}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div className='row' style={{padding: 20}}>
                        {Constants.ButtonList.map(item => {
                            return(
                                <div className='col-lg-6' style={{padding: 10}} onClick={()=>{
                                    if(item.id != 4){
                                        clearPlanList();
                                        getPlanList(1, Languages.Local, item.startRange, item.endRange);
                                        history.push('/plan-list')
                                    }else{
                                        clearPlanList();
                                        getTelsimPlanList(1, Languages.Local);
                                        handleModalClose();
                                        history.push('/plan-list')
                                    }
                                    
                                }}>
                                    <div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White}}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleModalClose}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default withRouter(ChangeBundle);