import * as React from 'react';
import { useState } from 'react';
import { Languages, Colors } from '../../../common'
import flagImg from '../../../assets/img/flag-australia.png'

const CountryItem = ({ type, data, onClick }) => {
    return (
        <div className={(data.type !== undefined && data.type != null) ? 'col-lg-6' : type == Languages.Local ? 'col-lg-4' : type == Languages.SupportedCountries ? 'col-lg-12' : 'col-lg-6'} onClick={onClick}>
            <div className='cntryBox' style={{ marginTop: 30 }}>
                {/* <img src={type == Languages.Local ? data.flag_image : type == Languages.SupportedCountries ? data.country_list.flag_image : data.image} style={{height: 30, width: type == Languages.Local ? 40 : 45, borderRadius: 5, border: '1px solid var(--secondary)'}} alt="" />
                <h6 style={{marginLeft: 10, marginTop: 5}}>{type == Languages.Local ? data.country_name : type == Languages.SupportedCountries ? data.country_list.country_name : data.region_name}</h6> */}
                <img src={ type === Languages.Local ? data?.flag_image : type === Languages.SupportedCountries ? data?.country_list?.flag_image : data?.image } style={{ height: 30, width: type === Languages.Local ? 40 : 45, borderRadius: 5, border: '1px solid var(--secondary)', }}alt=""/>
                <h6 style={{ marginLeft: 10, marginTop: 5 }} >{type === Languages.Local ? data?.country_name: type === Languages.SupportedCountries ? data?.country_list?.country_name: data?.region_name} </h6>
            </div>
        </div>
    )
}
export default CountryItem;