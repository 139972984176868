import axios from 'axios';
import {Constants} from '../common';

export default {
    async postData (data, headers, link) {
        const url = Constants.BaseUrl + link;
        return await axios.post(url, data, {headers})
        .then(async response => {
            //console.log('postData ==>', response.data);
            return response.data
        })
        .catch(error =>{
            //console.log('postData error ==>', error.response.data);
            return error.response.data
        });
    },
    async getData (headers, link) {
        const url = Constants.BaseUrl + link;
        return await axios.get(url, {headers})
        .then(async response => {
            //console.log('getData ==>', response.data);
            return response.data
        })
        .catch(error =>{
            //console.log('getData error ==>', error.response.data);
            return error.response.data
        });
    },
}