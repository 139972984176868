import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, withRouter, useHistory} from "react-router-dom";
import {Validate, Colors, Constants, Languages} from '../../common';
import {getApiHeader} from '../../utils/CommonFunctions';
import { UserAPI } from '../../services';
import {Skeleton, Snackbar, useMediaQuery, MenuItem, InputLabel, FormControl, InputBase} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import PlanItem from '../Plan/PlanItem';
import { actions } from '../../redux/UserRedux';
import swal from 'sweetalert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const TopUp = () => {

    const history = useHistory()
    const dispatch = useDispatch();

    const [customer_activations, setCustomerActivations] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [plan_id, setPlanId] = useState(0);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [msgType, setMsgType] = useState(Constants.SnackBarType.Error);

    const activation_id = useSelector((state) => state.user.activationId);
    const eSIMName = useSelector((state) => state.user.eSIMName);
    const colorData = useSelector((state) => state.user.colorData);
    const planList = useSelector((state) => state.user.topUPList);
    console.log(planList);
    const topup_message = useSelector((state) => state.user.topup_message);
    console.log(topup_message);
    const isLoading =  useSelector((state) => state.user.isLoading);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const setActivationId = useCallback((activationId, eSIMName) => {
        dispatch(actions.setActivationId(activationId, eSIMName));
    }, [dispatch]);

    const getTopupPlanList = useCallback((id) => {
        actions.getTopupPlanList(dispatch, id);
    }, [dispatch]);

    const openLoader = useCallback(() => {
        actions.openLoader(dispatch);
    }, [dispatch]);

    useEffect(() => {
        getActivations();
    }, [eSIMName]);

    const getActivations = async () => {
        openLoader();
        setCustomerActivations([])
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData({}, headers, Constants.UserActivations)
        console.log('UserActivations ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                //setIsLoading(false);
                let data 		= response.data.activations; 
                let esim_Name = '';
                let activationId = 0
                if(data.length > 0){
                    esim_Name = data[0].country_name + ' - ' + data[0].iccid;
                    activationId = data[0].activation_id
                }
                //setESIMName(esimName)
                setCustomerActivations(data);
                if(activation_id == null){
                    await setActivationId(activationId, esim_Name)
                    await getTopupPlanList(activationId)
                }else{
                    await setActivationId(activation_id, eSIMName)
                    await getTopupPlanList(activation_id);
                }
            } else {
                //setIsLoading(false)
                showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            //setIsLoading(false)
            showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    };

    const fetchPlanDetails = async (plan_id) => {
        sessionStorage.setItem('plan_id', plan_id);
        sessionStorage.setItem('activation_id', activation_id);
        sessionStorage.setItem('type', Languages.TopupESIM);
        sessionStorage.setItem('colour', JSON.stringify(colorData));
        sessionStorage.setItem('bundle', Languages.TopUp);
        //window.location.href = "/plandetails";
        history.push('/plandetails');
    }

    //show messages
    const showMsg = (message, type) => {
        setMessage(message);
        setMsgType(type);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpen(false);
    };

    const handleChange =  async(e) => { 
		var activationId = e.target.selectedOptions[0].getAttribute('activationId');
        let country_name = e.target.selectedOptions[0].getAttribute('country_name');
        let iccid = e.target.selectedOptions[0].getAttribute('iccid');
        console.log(activationId, country_name, iccid);
        await setActivationId(activationId, country_name + ' - ' + iccid)
		await getTopupPlanList(activationId);
	};


    const topupAlert = (event) => {
        const textval = event.currentTarget.getAttribute("data-value");
        swal({
            title: textval,
            text: "", 
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
               history.push('/purchaseplan');
          } else {
            
          }
        });
    }

    
    //console.log(showDetails)
    return(
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={handleClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet" style={{marginTop: 20}}>
                                <div className="kt-portlet__head">
                                
                                    <div className='authHideView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div className="kt-portlet__head-label">
                                            {isLoading == false ? (
                                            <h3 className="kt-portlet__head-title">{Languages.TopupESIM}</h3>
                                            ) : (
                                                <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                            )}

                                        </div>
                                        {/* <h3 className="kt-portlet__head-title">{Languages.AccountSummary}</h3> */}
                                        {isLoading == false ? (<select className="form-select custom-select kt_selectpicker" style={{width: 250}} value={eSIMName} onChange={handleChange} name="activation">
                                            {/* <option value="">{Languages.SelectSIM}</option> */}
                                            { customer_activations.map((person, i) => <option className="m--bg-success kt-font-inverse-success" country_name={person.country_name} iccid={person.iccid} activationId={person.activation_id} value={person.country_name + ' - ' + person.iccid} key={i.toString()}>{person.country_name + ' - ' + person.iccid}</option>)} 

                                        </select>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1, display: 'flex', width: 250, height: 35}}/>
                                        )}
                                    </div>
                                    <div className="kt-portlet__head-label hideMobileView">
                                        {isLoading == false ? (
                                        <h3 className="kt-portlet__head-title">{Languages.TopupESIM}</h3>
                                        ) : (
                                            <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                        )}
                                    </div>
                                    
                                </div>
                                {isLoading == false ? (<select className="form-select custom-select kt_selectpicker hideMobileView" value={eSIMName} onChange={handleChange} name="activation">
                                    {/* <option value="">{Languages.SelectSIM}</option> */}
                                    { customer_activations.map((person, i) => <option className="m--bg-success kt-font-inverse-success" country_name={person.country_name} iccid={person.iccid} activationId={person.activation_id} value={person.country_name + ' - ' + person.iccid} key={i.toString()}>{person.country_name + ' - ' + person.iccid}</option>)} 

                                </select>
                                ) : (
                                    <Skeleton className='hideMobileView' animation="wave" sx={{borderRadius: 1, display: 'flex', flex: 1, height: 40}} height={40}/>
                                )}
                                {/* <CustomerMobileHeader/> */}
                                
                                {isLoading == false ? (<>
                                    {customer_activations.length > 0 ? (<>

                                        { topup_message == null ? 
                                            <>
                                                <div className='authHideView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <div style={{background: 'linear-gradient(to bottom, ' + colorData.top + ' 0%, ' + colorData.bottom + ' 100%)', textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 50, width: 250, position: 'absolute', right: 50}}
                                                        onClick={()=>{
                                                            sessionStorage.setItem('bundle', Languages.TopUp);
                                                            history.push('/change-plan')
                                                        }}>
                                                        {Languages.ChangeBundle}
                                                    </div>
                                                </div>
                                                <div className='hideMobileView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', left: 10, right: 10}}>
                                                    <div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 10, width: '100%', fontSize: 12, fontWeight: 'bold'}}
                                                        onClick={()=>{
                                                            sessionStorage.setItem('bundle', Languages.TopUp);
                                                            history.push('/change-plan')
                                                        }}>
                                                        {Languages.ChangeBundle}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='authHideView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <div style={{background: 'linear-gradient(to bottom, ' + colorData.top + ' 0%, ' + colorData.bottom + ' 100%)', textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 50, width: 250, position: 'absolute', right: 50}}
                                                       onClick={topupAlert} data-value={topup_message}>
                                                        {Languages.ChangeBundle}
                                                    </div>
                                                </div>
                                                <div className='hideMobileView' style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', left: 10, right: 10}}>
                                                    <div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 10, width: '100%', fontSize: 12, fontWeight: 'bold'}}
                                                        onClick={topupAlert} data-value={topup_message}>
                                                        {Languages.ChangeBundle}
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className='authHideView' style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column'}}>
                                            <div className='row' style={{marginLeft: 50, marginRight: 50}}>
                                                {planList.map(item => (
                                                    <PlanItem
                                                    key={"" + item.plan_id}
                                                    type={Languages.TopupESIM}
                                                    colorInfo={colorData}
                                                    planData={item}
                                                    onShowDetails={()=> {
                                                        fetchPlanDetails(item.plan_id)
                                                    }}
                                                    topupRdTxt={topup_message}
                                                    />
                                                ))}
                                            </div> 
                                        </div>
                                        <div className='hideMobileView'>
                                            <div className='row' style={{marginLeft: 10, marginRight: 10, marginBottom: 50}}>
                                                {planList.map((item) => (
                                                    <PlanItem 
                                                    key={"" + item.plan_id}
                                                    type={Languages.TopupESIM}
                                                    colorInfo={colorData}
                                                    planData={item}
                                                    onShowDetails={()=> {

                                                        fetchPlanDetails(item.plan_id)
                                                    }}
                                                    topupRdTxt={topup_message}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </>) : (
                                    <div className='col-lg-12' style={{padding: isMobile ? 20 : 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: isMobile ? 10 : 50}}>
                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                            {isLoading == false ? (<i class="fa fa-qrcode" style={{fontSize: '6em'}} aria-hidden="true"></i>) : (
                                                <Skeleton animation="wave" sx={{
                                                    borderRadius: 2,
                                                    height: 100,
                                                    width: 100}}/>
                                            )}
                                            {isLoading == false ? (<h4 style={{marginTop: 10, textAlign: 'center', color: Colors.Black}}>{Languages.NoeSIM}</h4>) : (
                                                <Skeleton animation="wave" sx={{
                                                    borderRadius: 2,
                                                    height: 30,
                                                    width: 200,
                                                    marginTop: -1}}/>
                                            )}
                                            {isLoading == false ? (<h6 style={{marginTop: 10, textAlign: 'center'}}>{Languages.NoeSIMInfo}</h6>) : (
                                                <Skeleton animation="wave" sx={{
                                                    borderRadius: 2,
                                                    height: 30,
                                                    width: 200,
                                                    marginTop: -1}}/>
                                            )}
                                            {isLoading == false ? (<div style={{backgroundColor: Colors.Primary, textAlign: 'center', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, color: Colors.White, paddingLeft: 20, paddingRight: 20, marginTop: 10}}
                                            onClick={()=>{
                                                history.push('/purchaseplan')
                                            }}>
                                            {Languages.NoSIMBtnTxt}
                                            </div>) : (
                                                <Skeleton animation="wave" sx={{
                                                    borderRadius: 2,
                                                    height: 40,
                                                    width: 100,
                                                    marginTop: 1}}/>
                                            )}
                                        </div>
                                    </div>)}
                                </>) : (
                                <div className='row' style={{marginLeft: 10, marginRight: 10}}>
                                    {Constants.ButtonList.map(item=>(
                                    <div className='col-lg-4'>
                                        <Skeleton className='planitemBox' animation="wave" sx={{borderRadius: 1}} height={250}/>
                                    </div>))}
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(TopUp);