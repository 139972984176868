import React, { Component } from "react";
import Moment from 'react-moment';
import Table from 'react-bootstrap/Table';  
import Button  from 'react-bootstrap/Button';  
import Modal  from 'react-bootstrap/Modal';  
import swal from 'sweetalert';
import {getApiHeader} from '../../../utils/CommonFunctions'; 
import PageLoader from '../../Loader/loader';
import Loader from "react-loader-spinner";
import PaymentTab from "./PaymentTab";
import CustomerMobileHeader from "../../Layout/CustomerMobileHeader";
import { Colors, Constants, Languages } from "../../../common";
import CardItem from "./CardItem";
import TranHistoryItem from "./TranHistoryItems";
import Stack from "@mui/material/Stack";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {Skeleton, Snackbar, Box, Switch, styled} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { UserAPI } from '../../../services';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;   

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: Colors.White,
            '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
        },
        '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        },
        '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        },
    }
));

const stripePromise = loadStripe(Constants.StripePublishKey);

export default class MyPayments extends Component {
    state = {
		customer_cards: [], 
		model_visibility: '',
        customer_payment: [],
        isLoading: false,
        customerId:null,
        tabValue: Languages.PaymentMethod,
        open: false,
        message: "",
        msgType: Constants.SnackBarType.Error,
        defaultCard: null
	}

    componentDidMount = async () => { 

		await this.getCustomerCards();
        await this.getPayments();
        // await this.getActivations();
	}

    getCustomerCards = async () => {  
        this.setState({isLoading: true})
        let headers 	= await getApiHeader();
        const response = await UserAPI.getData(headers, Constants.CardList);
        console.log('CardList ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                var customer_cards = [];
                var defaultCard = null;
                if(response.data !== undefined && response.data != null){
                    customer_cards = response.data.list || [];
                    defaultCard = response.data.default_source;
                }
                this.setState({customer_cards, defaultCard, isLoading: false});
            }else {
                this.setState({isLoading: false})
            }
        }else{
            this.setState({isLoading: false})
        }
    }

    setDefaultCard = async (event, card) => {
        this.setState({isLoading: true});
        var postData = {
            stripeCardId: card.id
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(postData, headers, Constants.DefaultCard)
        console.log('DefaultCard ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                this.setState({isLoading: false});
                await this.getCustomerCards();
                this.showMsg(response.message, Constants.SnackBarType.Success)
            } else {
                this.setState({isLoading: false})
                this.showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            this.setState({isLoading: false})
            this.showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    };

    onDeleteCard = async (card) => {
		this.setState({isLoading: true});
        var postData = {
            card_token: card.id
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(postData, headers, Constants.DeleteCard)
        console.log('DeleteCard ==>', response, card.id);
        if(response !== undefined){
            if(response.status == 200){
                this.setState({isLoading: false});
                await this.getCustomerCards();
                this.showMsg(response.message, Constants.SnackBarType.Success)
            } else {
                this.setState({isLoading: false})
                this.showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            this.setState({isLoading: false})
            this.showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    }

    getPayments = async () => {
        this.setState({isLoading: true});
        var postData = {
            current_page: 0,
            per_page_records: 10
        }
        let headers 	= await getApiHeader();
        const response = await UserAPI.postData(postData, headers, Constants.PaymentHistory)
        console.log('PaymentHistory ==>', response);
        if(response !== undefined){
            if(response.status == 200){
                var customer_payment = [];
                var customerId = 0
                if(response.data !== undefined && response.data != null){
                    if(response.data.customer_id !== undefined && response.data.customer_id != null){
                        customerId = response.data.customer_id
                    }
                    if(response.data.records !== undefined && response.data.records != null) {
                        customer_payment = response.data.records || [];
                    }
                }
                this.setState({customerId, customer_payment, isLoading: false})
            } else {
                this.setState({isLoading: false})
                this.showMsg(response.message, Constants.SnackBarType.Error)
            }
        }else {
            this.setState({isLoading: false})
            this.showMsg(Languages.ServerError, Constants.SnackBarType.Error);
        }
    };

    //show messages
    showMsg = (message, type) => {
        this.setState({message, msgType: type, open: true})
    }

    handleMsgClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        this.setState({open: false})
    };

    mySubmitHandler = async (event) => {
        return;
    }
    
    handleClose = () => this.setState({ model_visibility: false });
    
    handleShow = () => {
        this.setState({ model_visibility: true });
    }

render() {
    const {tabValue, open, msgType, message, isLoading, defaultCard} = this.state;

  return (
    <>
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content"> 
            <Snackbar open={open} autoHideDuration={6000} onClose={this.handleMsgClose} anchorOrigin={{ 'horizontal': 'center', 'vertical': 'bottom'}}>
                <Alert onClose={this.handleMsgClose} severity={msgType} sx={{ width: '100%' }}>
                {message}
                </Alert>
            </Snackbar>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="row">
                    <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                    {isLoading == false ? (
                                        <h3 className="kt-portlet__head-title">{Languages.MyPayments}</h3>
                                    ) : (
                                        <Skeleton animation="wave" sx={{borderRadius: 1}} width={150} variant="text"/>
                                    )}
                                    </div>
                                </div>
                                {isLoading == false ? (<PaymentTab 
                                onChange={(value)=> {
                                    this.setState({tabValue: value})
                                }}
                                />) : (
                                    <Skeleton animation="wave" sx={{borderRadius: 1, height: 80}} width={'100%'} />
                                )}
                                {tabValue == Languages.PaymentMethod && (
                                    <div>
                                        <div className="form-group row toggle_div" style={{marginTop: 10}}>
                                            {isLoading == false ? (<div className="col-xl-12 col-lg-12 col-form-label">
                                                <Button style={{backgroundColor: Colors.White, borderWidth: 0, color: Colors.Green}} variant="danger" onClick={this.handleShow}>
                                                <label style={{color: Colors.Green, fontSize: 14}}>{Languages.AddNewMethod}</label></Button>
                                            </div>) : (
                                                <Skeleton animation="wave" sx={{borderRadius: 1, height: 50, marginLeft: 2}} width={100} />
                                            )}
                                        </div>
                                        <div className="hideMobileView" style={{marginTop: -20}}>
                                            {isLoading == false ? (<Stack
                                            direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 1, sm: 2, md: 4 }}
                                                useFlexGap
                                                flexWrap="wrap"
                                            >
                                                {this.state.customer_cards?.map((card,i) => (
                                                    <CardItem 
                                                    data={card}
                                                    onChange={this.setDefaultCard}
                                                    isDefault={defaultCard == card.id}
                                                    onDeleteCard={this.onDeleteCard}/>
                                                ))}
                                            </Stack>) : (
                                            <Stack
                                            direction={{ xs: 'column', sm: 'row' }}
                                            spacing={{ xs: 1, sm: 2, md: 4 }}
                                            useFlexGap
                                            flexWrap="wrap">
                                                {Constants.ButtonList.map((card,i) => (
                                                    <Skeleton animation="wave" sx={{borderRadius: 1, height: 150, marginTop: -2}} width={'95%'} />
                                                ))}
                                            </Stack>)}
                                        </div>
                                        <div className="table-responsive authHideView" style={{textAlign:'center', marginTop: -20}}>
                                            {isLoading == false ? (<Table bordered responsive >
                                                <thead>
                                                    <tr className="trStyle">
                                                        <th className="payment_table_head">SNo.</th>
                                                        <th className="payment_table_head">Name on Card</th>
                                                        <th className="payment_table_head">Card No</th>
                                                        <th className="payment_table_head">Expiry Date</th>
                                                        <th className="payment_table_head">Manage</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{verticalAlign: 'middle'}}>
                                                {(this.state.customer_cards.length > 0) ? this.state.customer_cards.map((card,i) => 
                                                        <tr style={{alignItems: 'center', backgroundColor: ((i % 2) == 0) ? Colors.White : Colors.TableRow, cursor:'pointer'}}>
                                                            <th scope="row" className="payment_serial_no">{i+1}</th>
                                                            <td className="payment_content">{card.brand}</td>
                                                            <td className="payment_content">{"**** **** **** " + card.last4}</td>
                                                            <td className="payment_content">{card.exp_month + '/' + card.exp_year}</td>
                                                            <td style={{textAlign:'center'}}> 
                                                                <Button style={{marginLeft: '10px', backgroundColor: ((i % 2) == 0) ? Colors.White : Colors.TableRow, borderWidth: 0, marginRight: 20}} value={card.token} onClick={() => this.onDeleteCard(card)}><DeleteIcon sx={{ color: Colors.Primary }}/></Button>
                                                                <IOSSwitch sx={{ m: 1 }} checked={defaultCard == card.id} onChange={(e)=>{
                                                                    if(defaultCard != card.id){
                                                                        this.setDefaultCard(e, card)
                                                                    }
                                                                    }} />
                                                            </td>
                                                        </tr>
                                                    ) : <tr><td colSpan={5}>No records found</td></tr>}
                                                </tbody>
                                            </Table>) : (
                                                <Skeleton animation="wave" sx={{borderRadius: 1, height: 80}} width={'100%'} />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {tabValue == Languages.TransactionHistory && (
                                    <div style={{marginTop: 5}}>
                                        <div className="hideMobileView" style={{marginTop: 20}}>
                                            <Stack
                                            direction={{ xs: 'column', sm: 'row' }}
                                                spacing={{ xs: 1, sm: 2, md: 4 }}
                                                useFlexGap
                                                flexWrap="wrap"
                                            >
                                                { this.state.customer_payment?.map((customer_pay,j) =>(
                                                    <TranHistoryItem 
                                                    data={customer_pay}
                                                    customerId={this.state.customerId}
                                                    url={SERVER_URL}/>
                                                ))}
                                            
                                            </Stack>
                                            
                                        </div>
                                        <div className="kt-section__content authHideView" style={{marginTop: 10}}>
                                            <div className="table-responsive" style={{textAlign:'center'}} id="trans_history_container">
                                                <Table bordered responsive >
                                                    <thead>
                                                        <tr className="trStyle">
                                                            <th className="payment_table_head">SNo</th>
                                                            <th className="payment_table_head">Date & Time</th>
                                                            <th className="payment_table_head">{'Amount (USD)'}</th>
                                                            <th className="payment_table_head">Method</th>
                                                            <th className="payment_table_head">Status</th>
                                                            <th className="payment_table_head">Invoice</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{verticalAlign: 'middle'}}> 
                                                        { this.state.customer_payment?.map((customer_pay,j) => 
                                                        <tr style={{alignItems: 'center', backgroundColor: ((j % 2) == 0) ? Colors.White : Colors.TableRow, cursor:'pointer'}}>
                                                            <th scope="row">{j+1}</th>
                                                            <td>
                                                                <Moment format="DD-MM-YYYY HH:mm A">{customer_pay.created_at}</Moment>
                                                            </td>
                                                            <td>{customer_pay.amount}</td>
                                                            <td>{customer_pay.method}</td>
                                                            <td style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                                                <div className="rowView" style={{display: 'flex'}}>
                                                                    <div className="tcircleView" style={{backgroundColor: customer_pay.payment_status == 'Unpaid' ? Colors.Gray : customer_pay.payment_status == 'Success' ? Colors.Green : Colors.Primary}}>

                                                                    </div>
                                                                    <label className="statustxt">{ customer_pay.payment_status } </label>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(customer_pay.payment_status === 'Success') ? <a href={`${SERVER_URL}payments/invoice-download/${customer_pay.payment_id}/${this.state.customerId}`} target="_blank">
                                                                    <DownloadIcon sx={{ color: Colors.Primary, fontSize: 24 }}/></a> : null}
                                                            </td>
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
         
        <Modal className="paybox" show={this.state.model_visibility} onHide={this.handleClose}>
            <form onSubmit={this.mySubmitHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="kt-portlet__body">
                    <div className="kt-section kt-section--first">
                        <div className="kt-section__body">
                            <div id="erralert" className="alert-danger col-lg-8"></div>
                                <div>
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm 
                                        showMsg={this.showMsg}
                                        close={this.handleClose}
                                        getCardList={this.getCustomerCards}/>
                                    </Elements>
                                </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <button onClick={this.buy.bind(this)} className="btn btn-brand" id="saveBtn">Save & Submit</button>
                </Modal.Footer> */}
            </form>
        </Modal>
	</>
   )
}
}